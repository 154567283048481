.element-imagegrid {
  background: rgba(#3e685b, 0.1);
  padding: 32px 0;

  @include breakpoint(tablet) {
    padding: 64px 0;
  }

  @include breakpoint(xlarge) {
    padding: 96px 0;
  }

  .headline {
    text-align: center;
    color: #3f685c;
  }

  .image-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;

    @include breakpoint(tablet) {
      margin: 32px auto 0;
      width: 80%;
    }

    @include breakpoint(xxlarge) {
      margin-top: 64px;
    }
  }

  .image-grid-item {
    width: 50%;
    padding: 8px;

    @include breakpoint(tablet) {
      width: 25%;
      margin-top: 8px;
    }

    @include breakpoint(large) {
      padding: 16px;
    }
  }
}
