.pagetype-eventspage {
  .search-empty-results {
    padding: 30px 0;
  }
}

.events-items {
  @include list-reset;
  padding: 30px 0;
  position: relative;
  z-index: 1;

  @include breakpoint(tablet) {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 60px;
    margin: -60px -10px 0;
  }

  @include breakpoint(xxlarge) {
    margin: -60px -20px 0;
  }
}

.events-item {
  margin-bottom: 20px;

  @include breakpoint(tablet) {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
  }

  @include breakpoint(large) {
    width: percentage(1 / 3);
  }

  @include breakpoint(xxlarge) {
    padding: 0 20px;
    margin-bottom: 40px;
  }

  &__wrapper {
    background: $secondary-color;
    border: 5px solid $white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0 2px 10px 0 rgba(black, 0.4);
    height: 100%;
    display: block;
    transition: background-color 0.2s ease-in-out;

    @include breakpoint(tablet) {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    &:hover,
    &:focus {
      background: lighten($secondary-color, 5%);

      .events-item__inner {
        background: lighten($secondary-color, 5%);
      }
    }
  }

  &__thumbnail {
    position: relative;
    display: block;

    img {
      display: block;
    }
  }

  &__expired {
    @include font-serif;
    @include rfs(25);
    margin-bottom: 15px;
  }

  &__date {
    @include font-serif;
    @include rfs(25);
    display: inline-flex;
    align-items: flex-start;
    color: $white;
    font-weight: bold;
    margin-bottom: 15px;
    position: relative;

    span {
      width: 40px;
      height: 40px;
      color: $secondary-color;
      background: $white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      position: relative;
    }

    &:before {
      content: '';
      width: 90%;
      position: absolute;
      right: 0;
      bottom: calc(50% - 0.6em);
      border-bottom: 1px solid $white;
      z-index: 0;
    }
  }

  &__inner {
    background: $secondary-color;
    padding: 20px;
    color: $white;
    transition: background-color 0.2s ease-in-out;
  }

  &__title {
    margin: 0;
    color: $white;
  }
}
