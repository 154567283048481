.element-contactform {
  &:not(.panel--first) {
    @include panel-arrow(down, $white);
  }

  @include panel-padding;
  background: #f9f9f9;

  .element-top {
    text-align: center;
  }

  .panel-title {
    color: $secondary-color;
  }

  .form-success-message {
    padding-top: 30px;
    text-align: center;
  }
}

.contact-form-element-wrapper {
  max-width: 1150px;
  margin: auto;

  [type=submit] {
    margin: auto;
  }
}
