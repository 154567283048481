.element-leadinpanelgroup {

  &:not(.join-our-team) {
    @include panel-arrow(down, $white);
  }
  position: relative;

  &:before {
    z-index: 1;
  }
}

.lead-in-panel-items {
  @include breakpoint(large) {
    display: flex;
  }
}

.lead-in-panel-item {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  color: $white;
  text-align: center;
  padding: 40px 10px;

  @include breakpoint(tablet) {
    padding: 40px;
  }

  @include breakpoint(large) {
    width: 50%;
    padding: 60px 40px;
    display: flex;
    justify-content: center;
  }

  @include breakpoint(xxlarge) {
    padding: 100px 50px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }

  &:nth-child(odd):before {
    background: rgba($primary-color, 0.85);
  }

  &:nth-child(even):before {
    background: rgba($secondary-color, 0.8);
  }

  &:first-child {
    @include breakpoint(xlarge down) {
      padding-top: 60px;
    }
  }

  &:last-child {
    padding-bottom: 100px;

    @include breakpoint(tablet) {
      padding-bottom: 140px;
    }

    @include breakpoint(xxlarge) {
      padding-bottom: 100px;
    }
  }

  &__inner {
    position: relative;
    max-width: 685px;
    margin: auto;
  }

  &__title {
    @include rfs(50);
    line-height: 1.1;
    text-align: center;
    font-weight: normal;
    color: $white;

    strong {
      @include rfs(70);
      display: block;
    }
  }

  .cta-group {
    @include breakpoint(large) {
      padding-top: 40px;
    }
  }
}

.element-leadinpanelgroup {

  &.join-our-team {

    padding-top: 0;
    padding-bottom: 0;

    @include breakpoint(large) {
        padding-top: 0;
        padding-bottom: 0;
    }

    .job-posting-content__list {

      column-count: 1;
      text-align: left;

      @include breakpoint(medium) {
        column-count: 2;
      }

      li {
        display: flex;
      }
    }

    .lead-in-panel-item {

        @include breakpoint(large) {
            display: block;
            justify-content: none;
        }

    }
  }

}
