.event-ticket-form-wrapper {
  border-top: 1px solid rgba($white, 0.15);
  padding-bottom: 40px;
  display: none;

  @include breakpoint(tablet) {
    padding: 20px 0 0;
    margin-top: 30px;
  }

  &__top {
    text-align: center;
    padding: 30px 0 45px;
  }

  &__title {
    @include rfs(55);
    color: $white;
  }

  fieldset {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  .form-success-message,
  .form-error-message {
    text-align: center;
  }

  .form-actions {
    border-top: 1px solid rgba($white, 0.15);
    padding-top: 30px;

    @include breakpoint(large) {
      padding-top: 45px;
    }

    button {
      background: transparent;
      border-color: $white;
      color: $white;

      &:hover,
      &:focus {
        background: $accent-color;
        border-color: $accent-color;
        color: $white;
      }

      margin: auto;
    }
  }
}

.event-tickets-wrapper {
  background: #013752;
  color: $white;
  border-radius: 24px;
  padding: 30px 20px;

  @include breakpoint(tablet) {
    padding: 30px;
  }

  @include breakpoint(xxlarge) {
    padding: 30px 40px 40px;
  }

  &__inner {
    @include breakpoint(tablet) {
      display: flex;
      align-items: center;
    }
  }

  .pristine-error {
    position: relative;
    top: auto;
    margin-top: 5px;
  }
}

.event-tickets-cost {
  text-align: center;
  margin-bottom: 20px;

  @include breakpoint(tablet) {
    margin: 0;
    flex: 1 0 40%;
  }

  @include breakpoint(xlarge) {
    flex-basis: 35%;
  }

  &__title {
    @include font-serif;
    @include rfs(55);
    color: $white;
    font-weight: normal;
    line-height: 1;
    margin-bottom: 5px;

    strong {
      font-style: italic;
    }
  }

  &__label {
    @include rfs(24);
    text-transform: uppercase;
  }
}

.event-tickets-fields {
  @include breakpoint(tablet) {
    flex: 1 0 60%;
  }

  @include breakpoint(xlarge) {
    flex-basis: 65%;
  }

  dl {
    @include breakpoint(large) {
      display: flex;
      align-items: baseline;
    }
  }

  dt {
    margin-bottom: 10px;

    @include breakpoint(large) {
      margin: 0;
      text-align: right;
      width: 200px;
      flex-shrink: 0;
      padding-right: 20px;
    }

    @include breakpoint(xlarge) {
      width: 300px;
      padding-right: 25px;
    }
  }

  dd {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  &__number {
    .field__inner {
      display: flex;
      align-items: center;
    }

    input {
      max-width: 75px;
      margin-right: 20px;
    }

    input[type=number] {
      appearance: textfield;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      appearance: none;
    }
  }

  &__holders {
    .field:first-child {
      @include breakpoint(medium down) {
        margin-bottom: 10px;
      }
    }

    .field {
      @include breakpoint(tablet) {
        max-width: calc(50% - 10px);
      }
    }

    dd {
      @include breakpoint(tablet) {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  dl.event-tickets-fields__donation {
    border-top: 1px solid rgba(white, 0.3);
    margin-top: 25px;
    padding-top: 15px;
    align-items: center;

    @include breakpoint(large) {
      padding-top: 25px;
    }

    dd {
      width: 100%;
    }

    input {
      @include breakpoint(tablet) {
        max-width: calc(60% - 10px);
      }
    }
  }

  dl.event-tickets-fields__business {
    align-items: center;

    dd {
      width: 100%;
    }

    input {
      @include breakpoint(tablet) {
        max-width: calc(60% - 10px);
      }
    }
  }

  &__total {
    @include rfs(24);
    border-top: 1px solid white;
    margin-top: 25px;
    padding-top: 15px;
    text-align: right;

    span {
      display: inline-block;
      margin-left: 10px;
    }
  }
}

.event-tickets-payment {
  padding-top: 20px;

  &__title {
    @include rfs(24);
    color: $white;
    margin-bottom: 0.3em;
  }

  &__notice {
    font-style: italic;
    margin-bottom: 20px;
  }

  &__card {
    background: $white;
    padding: 12px;
  }
}
