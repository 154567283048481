.element-calloutlist {
  position: relative;
  background: #4a9c2f;
  color: white;
  padding: 80px 0 32px;

  @include breakpoint(tablet) {
    padding: 172px 0 64px;
  }

  @include breakpoint(large) {
    padding-top: 200px;
  }

  @include breakpoint(xlarge) {
    padding: 260px 0 96px;
  }

  @include breakpoint(xxlarge) {
    padding-top: 300px;
    font-size: 22px;
  }

  &:after {
    content: '';
    background: white;
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 90px;

    @include breakpoint(tablet) {
      height: 140px;
    }

    @include breakpoint(large) {
      height: 160px;
    }

    @include breakpoint(xlarge) {
      height: 210px;
    }

    @include breakpoint(xxlarge) {
      height: 240px;
    }
  }

  .panel-top {
    text-align: center;
    max-width: 1150px;
    margin: auto;

    @include breakpoint(large) {
      padding: 0 96px;
    }
  }

  .headline {
    color: white;
    line-height: 1.2;
  }

  .panel-content {
    font-weight: bold;
  }

  .callout-list {
    @include list-reset;
    margin: 32px 0;

    @include breakpoint(large) {
      padding: 0 96px;
    }

    @include breakpoint(xlarge) {
      padding: 0 128px;
      margin: 64px 0;
    }

    @include breakpoint(xxlarge) {
      margin: 96px 0;
    }
  }

  .callout-list-item {
    margin-top: 32px;

    @include breakpoint(tablet) {
      display: flex;
      align-items: flex-start;
    }

    &__image {
      width: 80px;
      margin-bottom: 24px;

      @include breakpoint(tablet) {
        width: 100px;
        margin: 0 24px 0 0;
        flex-shrink: 0;
      }

      @include breakpoint(large) {
        width: 130px;
      }
    }

    &__title {
      color: white;
      margin-bottom: 0.2em;
      font-size: 24px;

      @include breakpoint(tablet) {
        margin-top: 0.2em;
      }

      @include breakpoint(large) {
        margin-top: 0.5em;
      }

      @include breakpoint(xlarge) {
        font-size: 30px;
      }

      @include breakpoint(xxlarge) {
        font-size: 40px;
        margin-top: 0;
      }
    }
  }

  .panel-image {
    position: relative;
    z-index: 1;
    width: 300px;
    margin: auto;

    @include breakpoint(tablet) {
      width: 400px;
    }

    @include breakpoint(large) {
      width: 500px;
    }

    @include breakpoint(xlarge) {
      width: 600px;
    }

    @include breakpoint(xxlarge) {
      width: 750px;
    }
  }
}
