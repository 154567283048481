.element-programwhyconsider {
  @include panel-padding($bottom: false);
  background: $secondary-color;
  color: $white;

  @include breakpoint(xxlarge) {
    padding-top: 60px;
  }
}

.why-consider-items {
  position: relative;

  @include breakpoint(large) {
    display: flex;
    justify-content: center;
    margin: 0 -20px;
  }

  @include breakpoint(xxlarge) {
    margin: 0 -40px;
  }

  &:before {
    @include breakpoint(large) {
      content: '';
      width: 66%;
      border-bottom: 1px solid rgba($white, 0.3);
      position: absolute;
      top: 80px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.why-consider-item {
  text-align: center;
  padding: 20px;
  position: relative;

  @include breakpoint(tablet only) {
    max-width: 460px;
    margin: auto;
  }

  @include breakpoint(large) {
    width: 50%;
    padding: 20px 20px 60px;
  }

  @include breakpoint(xxlarge) {
    padding: 20px 40px 60px;
  }

  @include breakpoint(xlarge) {
    width: percentage(1 / 3);
  }

  &:not(:first-child):before {
    @include breakpoint(xxlarge) {
      content: '';
      height: calc(100% - 170px);
      border-left: 1px solid rgba($white, 0.3);
      position: absolute;
      left: 0;
      top: 170px;
    }
  }

  &__icon {
    background: $secondary-color;
    border-radius: 50%;
    border: 1px solid $white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    margin: auto auto 20px;

    [data-icon] {
      height: 50px;
      width: 75px;
    }
  }

  &__title {
    @include rfs(55);
    @include font-serif;
    color: $white;
    font-weight: normal;
    line-height: 1;
    text-align: center;

    strong {
      display: block;
      font-style: italic;
    }
  }
}
