.news-header-articles {
  .page-header {
    &__title {
      max-width: none;

      a {
        @include anchor-hover($white);
        font-weight: 600;
      }
    }
  }

  .flickity-page-dots {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);

    @include breakpoint(large) {
      bottom: 65px;
    }
  }

  .news-header {
    text-align: center;

    &__date {
      @include rfs(25);
      display: inline-block;
      margin-bottom: 30px;
      font-weight: bold;

      &:after {
        content: '';
        width: 90%;
        margin: 10px auto 0;
        display: block;
        border-bottom: 1px solid $white;
      }
    }
  }
}

.news-header-article {
  .page-header__inner {
    max-width: 860px;
  }

  &__date {
    @include font-serif;
    @include rfs(45);
    display: inline-flex;
    align-items: baseline;
    color: $white;
    position: relative;
    font-weight: bold;

    span {
      width: 50px;
      height: 50px;
      color: $dark-blue;
      background: $accent-color;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      position: relative;

      @include breakpoint(tablet) {
        width: 64px;
        height: 64px;
        margin-right: 15px;
      }

      @include breakpoint(xlarge) {
        width: 72px;
        height: 72px;
        margin-right: 20px;
      }
    }

    &:before {
      content: '';
      width: 90%;
      position: absolute;
      right: 0;
      bottom: 1px;
      border-bottom: 1px solid $white;
      z-index: 0;
    }
  }
}

