.story-milestones {
  @include list-reset;
  @include panel-padding($top: false);
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;

  @include breakpoint(tablet) {
    width: 80%;
    margin: -60px auto 60px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background: $white;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    max-width: 1000px;
  }

  @include breakpoint(xlarge) {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.story-milestone {
  text-align: center;

  &:not(:first-child):before {
    content: '';
    height: 100px;
    width: 1px;
    border-left: 1px solid $dark-gray;
    display: block;
    margin: 2rem auto;

    @include breakpoint(large) {
      height: 200px;
    }
  }

  &__image {
    display: block;
    margin-bottom: 2rem;
    position: relative;

    @include breakpoint(large) {
      margin-bottom: 3rem;
    }

    img {
      display: block;
      border-radius: 6px;
      margin: auto;
      box-shadow: 0 2px 10px 0 rgba(black, 0.4);
    }

    [data-icon] {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.5);
      color: $white;
      transition: color 0.2s ease-in-out;

      @include breakpoint(tablet) {
        transform: translate(-50%, -50%) scale(0.75);
      }

      @include breakpoint(large) {
        transform: translate(-50%, -50%) scale(1);
      }
    }

    &:hover [data-icon],
    &:focus [data-icon] {
      color: $accent-color;
    }
  }

  &__title {
    @include font-serif;
    @include rfs(100);
    font-style: italic;
    color: $secondary-color;
    line-height: 1;
    margin-bottom: 0.2em;
  }

  &__content {
    max-width: 820px;
    margin: auto;

    @include breakpoint(tablet) {
      @include rfs(25);
    }
  }

  p:last-child {
    margin: 0;
  }
}
