.element-testimonials {
  @include panel-padding;

  .panel-title {
    font-weight: normal;
    line-height: 1.2;
    margin-bottom: 1em;

    strong {
      display: block;
    }
  }
}

.testimonials-slider {
  @include breakpoint(large) {
    max-width: calc(100% - 120px);
    margin: auto;
  }

  @include breakpoint(xlarge) {
    max-width: calc(100% - 160px);
  }

  &--double {
    .testimonials-item {
      @include rfs(18);
      text-align: left;

      @include breakpoint(tablet) {
        width: 50%;
      }

      @include breakpoint(xxlarge) {
        display: flex;
      }

      &:nth-child(odd) {
        @include breakpoint(tablet) {
          padding-right: 30px;
        }
      }

      &:nth-child(even) {
        @include breakpoint(tablet) {
          border-left: 1px solid $medium-gray;
          padding-left: 30px;
        }
      }

      [data-icon="quotes"] {
        width: 90px;
        height: 60px;
        color: rgba($black, 0.15);
        margin-bottom: -35px;
        opacity: 0.6;
        flex-shrink: 0;

        @include breakpoint(xxlarge) {
          margin: 0 20px 0 0;
        }
      }

      &__quote {
        font-style: normal;

        &:not(.no-line):after {
          display: none;
        }
      }

      &__quotee {
        @include rfs(25);
      }
    }
  }
}

.testimonials-item {
  @include rfs(25);
  text-align: center;

  [data-icon="quotes"] {
    color: rgba($secondary-color, 0.25);
    margin-bottom: -50px;
    width: 105px;
    height: 77px;
  }

  &__quote {
    font-style: italic;
    max-width: 1020px;
    margin: auto auto 20px;

    &:not(.no-line):after {
      content: '';
      display: block;
      max-width: 375px;
      width: 100%;
      margin: 30px auto auto;
      border-bottom: 1px solid $dark-gray;
    }
  }

  &__quotee {
    color: $secondary-color;
  }

  &__person {
    font-weight: bold;
  }

  &__more {
    font-size: 16px;
    color: $secondary-color;
    display: inline-flex;
    align-items: center;
    margin-left: 5px;

    [data-icon] {
      margin-right: 10px;
    }
  }
}

.testimonial-modal {
  width: 100%;
  padding: 20px;

  @include breakpoint(tablet) {
    padding: 30px;
    width: 80vw;
  }

  @include breakpoint(large) {
    padding: 60px;
  }

  .testimonials-item {
    @include rfs(22);
  }
}
