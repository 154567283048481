.page-search {
  position: relative;
  margin-bottom: 30px;

  .page-header & {
    margin: 0;

    .toggle--filters {
      color: $white;
    }
  }

  &:not(.page-search--no-bg) {
    background: $lighter-gray;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -19px;
      height: 100%;
      width: 100vw;
      background: $lighter-gray;
      z-index: 0;
    }
  }

  &__inner {
    min-height: 0;
    padding: 20px 0;
    position: relative;
    z-index: 1;

    @include breakpoint(tablet) {
      display: flex;
    }
  }

  &__filters {
    @include breakpoint(tablet) {
      display: flex;
      flex-grow: 1;
      flex-shrink: 0;
    }
  }

  &__field {
    @include breakpoint(medium down) {
      padding-top: 20px;
    }

    @include breakpoint(tablet) {
      margin-right: 20px;
      width: 50%;
    }
  }

  .btn--subscribe {
    padding: 14px 20px 12px;
    height: 54px;

    @include breakpoint(medium down) {
      margin-top: 20px;
    }

    @include breakpoint(tablet) {
      max-width: 180px;
    }

    @include breakpoint(large) {
      padding: 17px 20px 13px;
      max-width: 240px;
      height: 62px;
    }
  }
}

.page-search-results-heading {
  padding: 20px 0;

  @include breakpoint(tablet) {
    display: flex;
    align-items: center;
  }

  .btn {
    max-width: none;

    @include breakpoint(medium down) {
      margin-top: 10px;
    }

    @include breakpoint(tablet) {
      margin-left: 15px;
    }
  }
}
