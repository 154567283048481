.job-postings-wrapper {
  @include panel-padding;
}

.job-postings-empty {
  @include rfs(25);
  font-weight: bold;
}

.job-postings-filters {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.job-postings-filter {
  @extend %base-btn;
  border-radius: 8px;
  padding: 8px 15px 6px;
  font-size: 15px;
  width: auto;
  margin-right: 15px;

  &.is-active {
    background: $accent-color;
    color: $white;
  }
}

.job-postings-list {
  @include list-reset;
  padding-bottom: 30px;

  @include breakpoint(tablet) {
    padding-bottom: 45px;
  }
}

.job-postings-item {
  padding-top: 30px;

  @include breakpoint(tablet) {
    padding-top: 45px;
  }

  &__title {
    @include rfs(25);
    line-height: 1.3;
    margin: 0;
    cursor: pointer;
    display: inline-block;

    &:hover,
    &:focus {
      color: $accent-color;
    }
  }

  &__program {
    @include rfs(20);
    font-weight: 300;
    color: $black;
    line-height: 1.3;
    margin: 0;
  }
}
