.video-block {
  &__inner {
    position: relative;
    display: block;
    margin-bottom: 1.5em;

    &:before {
      content: '';
      background: rgba(black, 0.35);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      transition: background-color 0.2s ease-in-out;
    }

    &:hover:before {
      background: rgba(black, 0.1);
    }
  }

  &__title {
    font-weight: bold;
    text-align: center;
    color: $accent-color;
    text-transform: uppercase;
  }

  img {
    display: block;
    width: 100%;
  }

  [data-icon] {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $white;
    z-index: 2;
    width: 50%;
    height: 50%;
    max-width: 130px;
    max-height: 130px;
  }
}
