.element-callout {
  background: $blue;
  color: white;
  padding: 32px 0;

  @include breakpoint(large) {
    padding: 48px 0 64px;
  }

  @include breakpoint(xlarge) {
    padding-bottom: 96px;
  }

  .headline {
    color: white;
    text-align: center;

    strong {
      font-style: italic;
    }
  }

  .panel-content {
    text-align: center;
    max-width: 1040px;
    margin: auto;

    @include breakpoint(xxlarge) {
      font-size: 22px;
    }

    a {
      font-weight: bold;
      text-decoration: underline;
      color: white;

      &:hover {
        opacity: 0.7;
      }
    }

    span[style="text-decoration: underline;"] {
      font-weight: bold;
    }
  }

  .callout-list {

    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;
    margin: 2rem auto;
    max-width: 65rem;

    @include breakpoint(large) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    &__item {
      text-align: center;
    }

    &__icon {
      width: 5rem;
      height: 5rem;

      @include breakpoint(large) {
        width: 6rem;
        height: 6rem;
      }

      background-color: #fff;
      border-radius: 50%;
      color: $blue;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 1rem auto;
      svg {
        max-width: 50%;
        max-height: 60%;
        fill: $blue;
      }
    }

    &__title {

      font-size: 1rem;
      line-height: 1.25;

      @include breakpoint(large) {
        font-size: 1.25rem;

      }

      strong {
        display: block;
      }

    }

  }
}

.element-callout {

  &.join-our-team {

    margin-top: 2rem;
    margin-bottom: 0;

    @include breakpoint(large) {
      margin-top: 4rem;
      margin-bottom: 0;
    }

  }

}
