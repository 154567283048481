.element-ipastats {
  @include panel-arrow(down, $white);
  background: $primary-color;
  color: $white;
  padding: 30px 0;
  position: relative;
  z-index: 1;

  @include breakpoint(large) {
    padding: 45px 0;
  }

  .bg-icon {
    background-repeat: no-repeat;
    background-size: cover;
    width: 280px;
    height: 280px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;

    @include breakpoint(tablet) {
      width: 210px;
      height: 210px;
    }

    @include breakpoint(large) {
      width: 250px;
      height: 250px;
    }

    @include breakpoint(xxlarge) {
      width: 300px;
      height: 300px;
    }
  }
}

.ipa-stats-items {
  @include list-reset;
  padding-top: 20px;
  position: relative;

  @include breakpoint(tablet) {
    display: flex;
    justify-content: space-between;
    width: 85%;
    max-width: 980px;
    margin: auto;
  }

  .counter {
    text-align: center;
    padding: 10px 0;

    @include breakpoint(tablet) {
      padding: 20px;
    }

    @include breakpoint(xxlarge) {
      padding: 30px;
    }

    &__value {
      @include rfs(96);
      font-weight: bold;
      line-height: 1;
    }

    &__title {
      @include rfs(42);
      max-width: 280px;
      margin: auto;
    }
  }
}
