.element-wraparoundprinciples {
  color: $white;
}

.principles-wrapper {
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 30px 0;

  @include breakpoint(tablet) {
    padding: 45px 0 90px;
  }

  &:before {
    content: '';
    background: rgba(black, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  & > div {
    position: relative;
    z-index: 1;
  }
}

.principles-top {
  text-align: center;
  max-width: 1180px;
  margin: auto auto 30px;
}

.principles-items {
  position: relative;

  @include breakpoint(tablet) {
    padding: 20px 0;
  }

  @include breakpoint(xlarge) {
    padding: 40px 0;
  }

  &:before {
    @include breakpoint(tablet) {
      content: '';
      border-left: 1px solid $white;
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      z-index: 0;
    }
  }
}

.principles-item {
  $self: &;

  display: flex;
  padding: 10px 0;
  justify-content: center;
  position: relative;

  @include breakpoint(medium down) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  @include breakpoint(tablet) {
    align-items: flex-start;
    padding: 20px 0;
  }

  &__title {
    @include rfs(25);
    color: $white;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0.5em;
    transition: all 0.2s ease-in-out;

    @include breakpoint(tablet) {
      width: calc(50% - 40px);
      flex-shrink: 0;
      text-align: right;
      margin-top: 0.4em;
    }
  }

  &__icon {
    color: $white;
    transition: all 0.2s ease-in-out;

    @include breakpoint(medium down) {
      order: -1;
      margin-bottom: 0.5em;
    }

    @include breakpoint(tablet) {
      background: $white;
      color: $accent-color;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      flex-shrink: 0;
      margin: 0 20px;
    }

    [data-icon] {
      height: 50px;
      width: 50px;

      @include breakpoint(tablet) {
        height: 22px;
        width: 22px;
      }
    }
  }

  &__content-wrapper {
    position: relative;

    @include breakpoint(tablet) {
      width: calc(50% - 40px);
      flex-shrink: 0;
      margin-top: 0.4em;
    }
  }

  &__content {
    transition: opacity 0.2s ease-in-out;

    @include breakpoint(tablet) {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }

  &.is-active {
    #{$self}__title {
      color: $accent-color;
    }

    #{$self}__icon {
      background: $accent-color;
      color: $white;
    }

    #{$self}__content {
      opacity: 1;
    }
  }
}
