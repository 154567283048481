.events-search {
  .page-header & {
    margin-top: 20px;
  }

  .page-search__inner {
    max-width: 1050px;

    @include breakpoint(tablet) {
      flex-wrap: wrap;
    }
  }

  .page-search__filters {
    @include breakpoint(tablet only) {
      width: 100%;

      .page-search__field:last-child {
        margin-right: 0;
      }
    }
  }

  .events-search__category {
    @include breakpoint(large) {
      width: 45%;
    }
  }

  .events-search__date {
    @include breakpoint(large) {
      width: 55%;
    }
  }

  .btn--subscribe {
    @include breakpoint(tablet only) {
      margin-top: 20px;
      margin-left: auto;
    }

    @include breakpoint(large only) {
      max-width: 160px;
    }
  }

  .page-search-results-heading {
    width: 100%;
  }
}
