.pagetype-homepage {
  .element-leadingroup.panel--first {
    @include breakpoint(medium) {
      margin-bottom: 2rem;
    }
    @include breakpoint(large) {
      margin-top: -100px;
      margin-bottom: 4rem;
    }
  }
}
