.ar-ipa-block {
  position: relative;

  @include breakpoint(tablet) {
    display: flex;
  }

  @include breakpoint(1600px) {
    height: 900px;
  }

  &__inner {
    padding: 30px 20px;
    position: relative;
    z-index: 1;
    color: $white;
    text-align: center;
    margin-right: auto;

    @include breakpoint(tablet) {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @include breakpoint(large) {
      padding: 60px 40px;
    }

    @include breakpoint(xxlarge) {
      padding: 80px 40px;
    }
  }

  &--ipa {
    margin-bottom: 15px;

    .ar-ipa-block__inner {
      background: $secondary-color;

      @include breakpoint(tablet) {
        margin: 0 0 0 auto;
      }

      &:before {
        @include breakpoint(tablet) {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 50px 50px 50px 0;
          border-color: transparent $secondary-color transparent transparent;
          position: absolute;
          top: 50%;
          left: -50px;
          transform: translateY(-50%);
        }
      }
    }

    .ar-ipa-block__bg {
      @include breakpoint(tablet) {
        left: 0;
      }
    }
  }

  &--ar {
    .ar-ipa-block__inner {
      background: $primary-color;

      &:before {
        @include breakpoint(tablet) {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 60px 0 60px 60px;
          border-color: transparent transparent transparent $primary-color;
          position: absolute;
          top: 50%;
          right: -50px;
          transform: translateY(-50%);
        }
      }
    }
  }

  &__bg {
    height: 320px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    @include breakpoint(tablet) {
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
    }
  }

  &__prefix {
    @include rfs(75);
    @include font-serif;
    color: rgba($white, 0.5);
    line-height: 1;
    margin-bottom: 0.1em;

    strong {
      font-style: italic;
    }
  }

  &__title {
    @include rfs(55);
    line-height: 1.1;
    color: $white;
    max-width: 660px;
    margin: 0 auto 0.5em;
  }

  &__lead-in {
    max-width: 760px;
    margin: 0 auto;
  }

  &__image {
    margin-bottom: 1.5em;

    img {
      max-height: 150px;
      display: block;
      margin: auto;
      border: 8px solid $white;

      @include breakpoint(tablet) {
        max-height: 250px;
      }

      @include breakpoint(xxlarge) {
        max-height: 350px;
      }
    }
  }

  .cta-group {
    width: 100%;
    justify-content: center;
    align-items: center;

    .btn {
      max-width: 300px;
      width: 100%;
      border-color: $white;
      color: $white;
    }

    .text-link {
      color: $white;
      text-transform: uppercase;

      &:hover,
      &:focus {
        color: $accent-color;
      }

      @include breakpoint(xlarge down) {
        margin-top: 20px;
      }
    }

    &.has-multiple {
      @include breakpoint(tablet) {
        flex-direction: column;
      }

      @include breakpoint(xxlarge) {
        flex-direction: row;
      }

      .btn {
        margin: 0;

        @include breakpoint(xxlarge) {
          margin-right: 20px;
        }
      }
    }
  }
}
