.event-print {
  padding: 20px;
  font-weight: normal;
}

.event-print-empty {
  font-weight: bold;
  text-align: center;
  margin-top: 2em;
}

.event-header-section {
  margin-bottom: 3em;

  header {
    text-align: center;

    img {
      width: 194px;
      height: 57px;
      display: block;
      margin: auto;
      max-width: none;
    }
  }

  h2 {
    color: $primary-color;
    text-align: center;
    margin-top: 1em;
  }
}

.event-tickets-table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;

  th {
    text-align: left;
    padding: 8px;
  }

  td {
    border: 1px solid $light-gray;
    padding: 8px;
  }

  .purchaser {
    background: $light-gray;
  }
}

.event-sponsorship-level {
  margin-bottom: 3em;

  header {
    h3 {
      color: $primary-color;
    }
  }
}

.event-sponsors-table {
  border-collapse: collapse;
  width: 100%;

  th {
    text-align: left;
    padding: 8px;
  }

  td {
    border: 1px solid $light-gray;
    padding: 8px;
  }
}

@media print {
  .event-print-section {
    page-break-after: always;
  }

  .event-sponsors-table,
  .event-tickets-table {
    position: relative;
    font-size: 7pt !important;

    tr,
    td {
      page-break-inside: avoid;
    }
  }
}
