.pagetype-careerspage {
  .page-container {
    @include panel-padding($top: false);
  }
}

.job-posting-content {
  &--modal {
    padding: 20px;

    @include breakpoint(tablet) {
      padding: 40px;
    }

    @include breakpoint(xlarge) {
      padding: 60px;
    }
  }

  &__list {
    @include list-reset;
    padding-bottom: 40px;

    li {
      display: inline-flex;
      margin-bottom: 15px;
      align-items: baseline;

      [data-icon] {
        position: relative;
        top: 0.2em;
        margin-right: 0.5em;
        flex-shrink: 0;
      }

      &.theme--secondary [data-icon] {
        color: $secondary-color;
      }
    }
  }

  &__label {
    font-weight: 300;

    &.theme--secondary {
      color: $secondary-color;
    }

    &.theme--white {
      color: $white;
    }
  }

  &__details {
    padding-top: 20px;

    @include breakpoint(tablet) {
      max-width: 940px;
      margin: auto;
    }
  }

  &__benefits {
    background: $primary-color;
    color: $white;
    padding: 20px;

    @include breakpoint(tablet) {
      padding: 30px;
    }

    @include breakpoint(xlarge) {
      padding: 40px;
    }

    ul {
      padding: 0;

      @include breakpoint(tablet) {
        column-count: 3;
      }

      @include breakpoint(xlarge) {
        column-count: 4;
      }
    }
  }

  &__actions {
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include breakpoint(tablet) {
      padding-top: 60px;
    }

    a {
      max-width: 240px;
    }
  }

  &__lead-in {
    text-align: center;
  }

  &__disclaimer {
    text-align: center;
    font-style: italic;
    margin-bottom: 20px;
  }
}

.job-posting-sidebar-column {
  @include breakpoint(tablet) {
    order: 2;
  }
}

.job-posting-form {
  position: relative;

  @include breakpoint(large) {
    min-height: 860px;
  }

  @include breakpoint(xlarge) {
    padding-right: 60px;
  }

  @include breakpoint(xxlarge) {
    padding-right: 80px;
  }

  &:after {
    @include breakpoint(large) {
      content: '';
      height: 70%;
      width: 1px;
      background: $medium-gray;
      position: absolute;
      right: -45px;
      top: 0;
    }

    @include breakpoint(xlarge) {
      right: 0;
    }
  }

  &__inner {
    margin-bottom: 30px;
  }
}

.job-posting-sidebar {
  @include breakpoint(medium down) {
    margin-bottom: 40px;
  }

  @include breakpoint(large only) {
    padding-left: 40px;
  }
}


#careers-filter {
    .edd-head {
        background: #007ab8;
        border-color: #007ab8;
        border-radius: 25px;
    }

    .edd-value {
        color: #fff;
        padding: 13px 20px;
    }

    .edd-arrow:before {
        border-color: #ffffff !important;
    }

    .edd-option-focused:not(.edd-option-disabled) {
        color: #013752 !important;
    }

    input#search {
        background: #ffffff;
        text-align: left;
        border-color: #007AB8;
    }

    .styled-input-group button {
        background: #007ab8;
        border-color: #007ab8;
    }

    @include breakpoint(tablet) {
        border: 2px solid #007ab8;
        border-radius: 30px;

        .page-search__field.styled-dropdown {
            margin: unset;
        }

        .edd-head {
            border: unset;
            border-radius: unset;
            border-right: 1px solid #ffffff;
            width: 15rem;
        }

        .page-search__field:first-child .edd-head {
            border-top-left-radius: 25px !important;
            border-bottom-left-radius: 25px !important;
        }

        input#search {
            border: unset;
            border-radius: unset;
        }

        .page-search__field {
            margin-right: unset;
            width: 15rem;
        }

        .styled-input-group button:hover:not(:disabled) {
            background: #007ab8ed;
        }
    }

    @include breakpoint(xlarge) {
        .edd-head,
        .page-search__field {
            width: 26rem;
        }
    }
}

#careers-filter-clear {
    .clear {
        border-color: #007ab8;
        color: #007ab8;
    }

    .clear:hover {
        background: #007ab8ed !important;
        border-color: #007ab8 !important;
        color: #ffffff !important;
    }
}
