.site-widget {
  position: fixed;
  bottom: 0;
  z-index: 30;
  width: 100%;
  background: $blue;
  border: 1px solid transparent;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 0.75rem;
  max-width: 250px;
  color: white;
  overflow: hidden;
  transform: translate3d(0, 200%, 0);

  &:not(.no-animate) {
    transition: all 0.5s ease-in-out;
  }

  @include breakpoint(medium down) {
    right: calc(50% - 125px);
  }

  @include breakpoint(tablet) {
    right: 20px;
    max-width: 300px;
  }

  .site-widget-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 24px;
    font-weight: 600;
    cursor: pointer;
  }

  .site-widget-title {
    transition: opacity 0.2s ease-in-out;
    font-size: 18px;
  }

  .site-widget-items {
    transition: opacity 0.2s ease-in-out;
  }

  .site-widget-content {
    margin-top: 18px;
    padding: 0 16px;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-left: 16px;
  }

  .site-widget-top .for-expanded,
  .site-widget-top .for-collapsed {
    display: none;
  }

  .site-widget-top .site-widget-title,
  .site-widget-items {
    opacity: 0;
  }

  &[aria-expanded='true'] {
    .site-widget-top .for-expanded {
      display: block;
    }

    .site-widget-items {
      opacity: 1;
    }

    & {
      bottom: 20px;
    }
  }

  &[aria-expanded='false'] {
    .site-widget-top .for-collapsed {
      display: block;
    }

    .site-widget-top .site-widget-title {
      opacity: 1;
    }

    .site-widget-items {
      opacity: 0;
    }
  }
}
