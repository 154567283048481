.element-wraparoundleadin {
  @include panel-padding;

  .cell {
    @include breakpoint(tablet) {
      &:first-child {
        order: 2;
      }

      &:last-child {
        order: 1;
      }
    }
  }

  .video-block {
    max-width: 515px;
    margin-left: auto;

    @include breakpoint(medium down) {
      margin-bottom: 30px;
    }
  }
}

.wraparound-lead-in-top {
  margin-bottom: 30px;

  &__prefix {
    @include rfs(75);
    @include font-serif;
    color: #007AB8;
    line-height: 1.3;

    strong {
      font-style: italic;
    }
  }

  &__title {
    @include rfs(55);
    margin-bottom: 0.5em;
  }
}


.page-frc{

	.wraparound-lead-in-top__prefix {
       line-height: 1;
	}
	.wraparound-lead-in-top__title {
    line-height: 1.2;
}


}

.element-wraparoundleadin {

  &.join-our-team {

    padding-top: 2rem;
    padding-bottom: 2rem;

    @include breakpoint(large) {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

  }

}
