.element-contentimagesplit {

    padding-bottom: 2rem;

    &.panel--first {

        padding-bottom: 2rem;
        padding-top: 1rem;

        @include breakpoint(large) {
            padding-bottom: 0;
            padding-top: 2rem;
        }
    }

    h2 {
        margin-bottom: 0;
    }

    .grid-container {

        @include breakpoint(large) {
            display: flex;
            align-items: center;
        }

        .block-image {

            margin-bottom: 1rem;

            @include breakpoint(large) {
                margin-right: 0;
                width: calc(50% + 50vw);
                margin-left: calc(50% - 50vw);
                margin-bottom: 0;
            }

        }


        .block-content {

            @include breakpoint(large) {

                width: 50%;
                flex-shrink: 0;
                padding: 2.25rem 0 2.25rem 3rem;

            }

            .headline {
                margin-bottom: 1rem;
                @include rfs(42);
                line-height: 1.2;

                @include breakpoint(large) {
                    margin-bottom: 30px;
                }
            }

        }

        &.image-right {

            @include breakpoint(large) {
                flex-direction: row-reverse;
            }

            .block-image {

                @include breakpoint(large) {

                    margin-left: 0;
                    width: calc(50% + 50vw);
                    margin-right: calc(50% - 50vw);

                    img {
                        display: inline-block;
                        vertical-align: middle;
                        max-width: 100%;
                        height: auto;
                    }

                }

            }

            .block-content {

                @include breakpoint(large) {

                    width: 50%;
                    flex-shrink: 0;
                    padding: 2.25rem 2.25rem 3rem 0;

                }

            }


        }

    }

}
