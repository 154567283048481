.definitions-search {
  .page-search__inner {
    max-width: 900px;
  }

  .definitions-search__keyword {
    input {
      background: $white;
    }

    input::placeholder {
        color: $dark-gray;
        opacity: 1;
    }

    input:-ms-input-placeholder {
      color: $dark-gray;
    }

    input::-ms-input-placeholder {
        color: $dark-gray;
    }
  }
}
