.cta.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  @include breakpoint(medium down) {
    min-width: 100%;
  }

  [data-icon] {
    margin-left: 8px;
    flex-shrink: 0;
  }
}

.cta.btn--video {
  @include rfs(32);
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover,
  &:focus {
    color: $accent-color;
  }

  [data-icon] {
    width: 64px;
    height: 64px;
    margin: 0 15px 0 0;
  }

  img {
    max-width: 250px;
    transition: transform 0.2s ease-in-out;
    display: block;
  }

  &:hover img,
  &:focus img {
    transform: scale(1.02);
  }
}

.cta-group {
  padding-top: 1em;

  &.has-multiple {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;

    @include breakpoint(tablet) {
      flex-direction: row;
    }

    .btn {
      max-width: none;
      width: 100%;

      @include breakpoint(medium down) {
        &:last-child:not(:first-child) {
          margin-top: 20px;
        }
      }

      @include breakpoint(tablet) {
        flex-grow: 1;
        width: auto;

        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }

  &--centered {
    display: flex;
    justify-content: center;
  }
}
