$media-ratio: percentage(458 / 636);

.story-page-header {
  .page-header__inner {
    @include breakpoint(tablet) {
      display: flex;
      justify-content: space-between;
    }
  }

  .page-header__columns {
    @include breakpoint(tablet) {
      width: 60%;
      padding-right: 40px;
    }

    @include breakpoint(xxlarge) {
      width: calc(100% - 630px);
    }
  }

  &__program {
    @include breakpoint(medium down) {
      margin-top: 40px;
    }

    @include breakpoint(large) {
      width: 40%;
      position: relative;
    }

    @include breakpoint(xxlarge) {
      width: 630px;
    }
  }

  &__title {
    @include rfs(25);
    padding: 0 20px 20px;
    text-transform: uppercase;
    line-height: 1.1;
    text-align: center;
    font-weight: bold;
    max-width: 300px;
    margin: auto;

    img {
      max-width: 250px;
    }
  }
}

.story-page-media {
  padding-top: $media-ratio;
  border: 5px solid $white;
  border-radius: 6px;
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;

  @include breakpoint(tablet only) {
    width: 500px;
    height: 360px;
    padding: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.story-content {
  @include breakpoint(tablet down) {
    margin-bottom: 40px;
  }
}

.story-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &__slide {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: linear-gradient(to bottom, transparent 30%, rgba(black, 0.6));
      pointer-events: none;
    }
  }

  &__video {
    display: block;
    position: relative;

    &:hover [data-icon],
    &:focus [data-icon] {
      color: $accent-color;
    }

    [data-icon] {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $white;
      z-index: 2;
      width: 50%;
      height: 50%;
      max-width: 130px;
      max-height: 130px;
    }
  }
}

.story-wrapper {
  @include panel-padding;

  @include breakpoint(large) {
    display: flex;
    justify-content: space-between;
  }

  &.has-media {
    margin-top: calc(#{$media-ratio} - 70px);

    @include breakpoint(tablet only) {
      margin-top: 290px;
    }

    @include breakpoint(large) {
      margin-top: 0;
    }

    .story-sidebar {
      @include breakpoint(large) {
        margin-top: calc(#{$media-ratio * 0.4} - 105px - 60px);
      }

      @include breakpoint(xxlarge) {
        margin-top: calc(#{$media-ratio * 0.4} - 120px - 15px);
      }
    }
  }
}

.story-content {
  @include breakpoint(large) {
    width: 60%;
    padding-right: 40px;
  }

  @include breakpoint(xxlarge) {
    width: 650px;
  }

  h2 {
    @include rfs(55);
  }

  h3, h4, h5, h6 {
    @include rfs(30);
    margin-top: 1em;
  }
}

.story-sidebar {
  @include breakpoint(tablet down) {
    padding-bottom: 30px;
  }

  @include breakpoint(large) {
    width: 40%;
  }

  @include breakpoint(xxlarge) {
    width: 630px;
  }

  &__quote {
    font-style: italic;
    padding-left: 15px;
    padding-bottom: 30px;

    @include breakpoint(tablet down) {
      padding-top: 30px;
    }

    @include breakpoint(xlarge) {
      display: flex;
      padding-left: 20px;
    }

    @include breakpoint(xxlarge) {
      padding-left: 40px;
    }

    [data-icon] {
      width: 86px;
      height: 65px;
      color: rgba($secondary-color, 0.25);
      margin-bottom: -40px;
      margin-left: -10px;

      @include breakpoint(xlarge) {
        margin: 0 20px 0 0;
        flex-shrink: 0;
      }
    }
  }
}

.story-other-stories {
  &__slide {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  &__block {
    width: percentage(1 / 3);
    padding: 3px;

    a {
      display: block;
      height: 100%;

      &:not(.is-active) {
        opacity: 0.4;
        transition: opacity 0.2s ease-in-out;

        &:hover,
        &:focus {
          opacity: 1;
        }
      }
    }

    img {
      width: 100%;
    }
  }

  &__name {
    display: flex;
    background: url(../images/default-stories-thumbnail.jpg) no-repeat;
    background-position: center;
    background-size: cover;
    align-items: center;
    justify-content: center;
    padding-top: 100%;
    position: relative;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 10px;
      width: 100%;
      text-align: center;
      color: $white;
      font-size: 14px;

      @include breakpoint(tablet) {
        font-size: 24px;
        padding: 20px;
      }

      @include breakpoint(large) {
        font-size: 16px;
      }

      @include breakpoint(xxlarge) {
        font-size: 24px;
      }
    }
  }

  &__counter {
    padding-top: 30px;
    text-align: center;
  }

  .flickity-prev-next-button {
    background: transparent;
    border-radius: 0;
    top: auto;
    bottom: -63px;
    transform: none;
    z-index: 10;

    @include breakpoint(xlarge) {
      bottom: -65px;
    }

    &.previous {
      left: calc(50% - 85px);
    }

    &.next {
      right: calc(50% - 85px);
    }

    .flickity-button-icon {
      color: $medium-gray;
    }
  }

  &.is-single {
    .flickity-prev-next-button {
      display: none;
    }
  }
}
