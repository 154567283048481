.site-menu-btn {
  @include hamburger($width: 28px, $height: 19px);
  @include text-hide;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  @include breakpoint(tablet down) {
    .site-header--fixed:not(.is-stuck) & {
      @include hamburger(white, $width: 28px, $height: 19px);
    }
  }

  @include breakpoint(large) {
    display: none;
  }

  .js-menu-active & {
    opacity: 0;
  }

  &-close {
    @include close-btn;
    position: relative;
    margin-left: auto;
    margin-right: 10px;
    font-size: 20px;
    color: $white;
  }
}

.js-menu-active {
  body {
    transform: translate3d(-$site-menu-width, 0, 0);
    position: sticky;

    &:before {
      opacity: 1;
      pointer-events: all;
    }
  }

  .site-nav {
    box-shadow: -5px 0 10px 0 rgba(black, 0.4);
  }
}

.site-nav {
  $self: &;
  color: $white;

  @include breakpoint(tablet down) {
    background: $primary-color;
    position: fixed;
    top: 0;
    right: -$site-menu-width;
    height: 100vh;
    width: $site-menu-width;
    z-index: 1000;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }

  @include breakpoint(large) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: $primary-color;
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    color: white;
    height: 55px;
    transition: background-color 0.3s ease-in-out;

    @include breakpoint(large) {
      display: none;
    }

    &-title {
      @include text-truncate;
      font-size: 16px;
      font-weight: bold;
      width: 180px;
      display: none;
    }

    &.is-active {
      background: darken($primary-color, 5%);

      #{$self}__prev {
        display: block;
      }

      .site-nav__header-title {
        display: block;
      }
    }
  }

  &__menu {
    transition: transform 0.3s ease-in-out;

    @include breakpoint(large) {
      margin-right: 75px;
    }
  }

  &__prev {
    font-size: 0;
    height: 100%;
    width: 45px;
    margin-right: 15px;
    background: darken($primary-color, 10%);
    display: none;
    color: inherit;
    position: relative;
    z-index: 2;

    [data-icon] {
      transform: rotate(90deg);
    }
  }

  &__next {
    @include text-hide;
    margin-left: auto;
    padding: 0 15px;
    color: inherit;

    @include breakpoint(large) {
      display: none;
    }

    &:hover,
    &:focus {
      [data-icon] g {
        opacity: 1;
      }
    }
  }

  &__actions {
    display: none;

    @include breakpoint(large) {
      margin: 0;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      display: flex;
      align-items: center;
    }

    .site-search-trigger {
      @include breakpoint(large) {
        font-size: 24px;
        margin-right: 1em;
      }

      @include breakpoint(xlarge) {
        font-size: 30px;
      }
    }

    .btn {
      margin: auto;
      width: 200px;

      @include breakpoint(large) {
        width: 180px;
      }

      @include breakpoint(xlarge) {
        width: 210px;
      }
    }
  }

  &__mobile-actions {
    @include breakpoint(large) {
      display: none;
    }
  }

  .site-search-trigger {
    color: $white;

    &:hover,
    &:focus {
      color: $accent-color;
    }

    [data-icon] {
      height: 1.1em;
      width: 1.1em;

      @include breakpoint(tablet down) {
        height: 24px;
        width: 24px;
      }
    }

    &--mobile {
      position: absolute;
      top: 15px;
      left: 15px;
      z-index: 1;
    }
  }

  .btn--mobile-wtg {
    max-width: 180px;
    margin: 20px auto 50px;

    @include breakpoint(large) {
      display: none;
    }
  }
}

.site-nav-contact {
  text-align: center;
  padding: 0 15px;

  [data-icon] {
    color: $accent-color;
    width: 27px;
    height: 28px;
  }

  &__phone {
    a {
      font-size: 25px;
      color: white;

      &:hover,
      &:focus {
        color: $accent-color;
      }
    }
  }

  &__link {
    font-size: 18px;

    &:hover,
    &:focus {
      color: lighten($accent-color, 15%);
    }
  }
}

.site-nav-programs {
  margin: 20px 15px;
  background: #c3dcee;
  border-radius: 6px;
  padding: 15px 7px 5px;

  &__top {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    color: #005288;
    margin-bottom: 10px;
  }

  .flickity-viewport {
    padding: 10px 0;
    height: 145px;
  }

  .flickity-button {
    top: -26px;
    width: 35px;
    height: 35px;
    background: transparent;

    &:focus {
      box-shadow: none;
    }

    svg {
      color: #005288;
    }
  }

  &__item {
    width: 50%;
    display: flex;
    justify-content: center;

    a {
      display: block;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      position: relative;
      border-radius: 6px;
      box-shadow: 0 2px 10px 0 rgba(black, 0.5);
      width: 120px;
      height: 120px;

      &:before {
        content: '';
        background-image: linear-gradient(to bottom, transparent, rgba(black, 0.5));
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        border-radius: 6px;
      }

      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: white;
        line-height: 1.2;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 10px;
        font-size: 15px;
      }
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
    }
  }
}

.site-nav-items {
  @include list-reset;
  transition: all 0.2s ease-in-out;

  @include breakpoint(large) {
    display: flex;
    justify-content: flex-end;
    position: relative;
  }

  &__submenu {
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    display: none;

    @include breakpoint(tablet down) {
      padding-bottom: 80px;
    }

    &.is-active {
      display: block;
    }
  }

  &--active {
    opacity: 1;
  }

  li {
    display: flex;
    align-items: center;
    margin: 15px 0;
    padding-left: 10px;
    padding-right: 5px;

    @include breakpoint(large) {
      margin: 0;
      padding-left: 10px;
      padding-right: 10px;

      &:last-child {
        padding-right: 0;
      }

      &.is-home {
        display: none;
      }
    }

    @include breakpoint(xxlarge) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  a {
    color: inherit;
    position: relative;

    &:hover,
    &:focus {
      color: $accent-color;

      &:after {
        width: 100%;
      }
    }

    @include breakpoint(tablet down) {
      display: flex;
      align-items: flex-start;
      line-height: 1.2;
    }

    &:after {
      @include breakpoint(large) {
        content: '';
        position: absolute;
        width: 0;
        height: 2px;
        background: $accent-color;
        bottom: -2px;
        left: 0;
        transition: width 0.2s ease-in-out;
      }
    }

    &.current:after,
    &.section:after {
      width: 100%;
    }

    [data-icon] {
      flex-shrink: 0;
      margin: 3px 3px 0 0;
      transform: rotate(-90deg) scale(0.6);

      @include breakpoint(large) {
        display: none;
      }
    }
  }
}

.site-header--fixed .site-nav {
  @include breakpoint(large) {
    color: $white;
  }
}

.site-header:not(.site-header--fixed) .site-nav,
.site-header.is-stuck .site-nav {
  @include breakpoint(large) {
    color: $primary-color;
  }

  &__actions {
    .site-search-trigger {
      color: $accent-color;
    }

    .btn {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
}

.site-header.is-stuck .site-nav-items {
    a {
        &:hover,
        &:focus {
            color: $secondary-color;
        }

        &:after {
            @include breakpoint(large) {
                background: $secondary-color;
              }
        }
    }
}

@include breakpoint(large) {

    .nav-has-submenu {


        position: relative;
        pointer-events: auto;
        z-index: 10;

        .nav-submenu {

            &:before {
                position: absolute;

                transform: translateX(-50%);
                left: 50%;
                content: '';
                width: 0;
                height: 0;
                top: 6px;
                border-style: solid;
                border-width: 0 5px 6px 5px;
                border-color: transparent transparent #ffffff transparent;

                transition-property: opacity;
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                transition-duration: 150ms;

            }

            position: absolute;
            min-width: 100%;
            opacity: 0;
            pointer-events: none;
            top: 100%;
            padding-top: 0.5rem;

            transition-property: opacity;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 150ms;

            transform: translateX(-50%);
            left: 50%;


            /*   transform  */

            ul {

                background-color: #fff;
                border-radius: 0.5rem;
                margin-top: 0.25rem;
                padding: 1.25rem 1.75rem;
                filter: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08));

                li {

                    padding: 0;

                    &:not(:last-child) {
                        margin-bottom: 0.5rem;
                    }

                    a {
                        white-space: nowrap;
                        @include anchor-hover($black, $blue);
                        font-size: 0.875rem;
                        line-height: 1.25rem;
                        font-weight: normal;
                        padding: 0;
                    }
                }

            }

        }


        &:hover {
            .nav-submenu {
                opacity: 1;
                pointer-events: auto;

                .site-nav-items__submenu {
                    position: relative;
                    display: block;
                    left: auto;
                }
            }
        }

    }

}
