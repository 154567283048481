.glance-about-wrapper {
  text-align: center;
  padding-bottom: 60px;

  @include breakpoint(large) {
    display: flex;
    align-items: center;
    margin-top: -75px;
  }

  @include breakpoint(xxlarge) {
    margin-top: -125px;
  }

  @include breakpoint(1560px) {
    margin-left: -60px;
  }
}

.glance-circle {
  position: relative;
  max-width: 350px;
  border: 20px solid $white;
  border-radius: 50%;
  margin: -60px auto 0;
  width: 100%;
  z-index: 2;

  @include breakpoint(large) {
    margin: 0 20px 0 0;
    max-width: none;
    width: 60%;
  }

  @include breakpoint(xlarge) {
    border-width: 40px;
  }

  @include breakpoint(xxlarge) {
    width: 525px;
    flex-shrink: 0;
  }

  &:before {
    content: '';
    padding-top: 100%;
    display: block;
  }

  svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}

.glance-services {
  @include list-reset;
  display: inline-block;
  position: relative;
  z-index: 1;

  @include breakpoint(tablet) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 620px;
    margin: auto;
  }

  @include breakpoint(large) {
    max-width: none;
    top: 60px;
  }

  @include breakpoint(xxlarge) {
    top: 80px;
  }

  &:before {
    content: '';
    height: 2px;
    background: $light-gray;
    position: absolute;
    top: 25px;
    left: -60px;
    z-index: 1;
    transition: width 0.4s ease-in-out;
    width: 0;
    z-index: -1;
    transition-delay: 0.6s;

    @include breakpoint(tablet down) {
      display: none;
    }

    @include breakpoint(xlarge) {
      top: 35px;
    }
  }

  &.aos-animate:before {
    width: 100%;
  }
}

.glance-service-item {
  display: flex;
  align-items: center;
  padding: 10px 0;

  @include breakpoint(tablet) {
    display: block;
  }

  @include breakpoint(large) {
    padding: 0;
  }

  &__icon {
    background: $white;
    color: $secondary-color;
    border: 1px solid $secondary-color;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    position: relative;
    z-index: 1;

    @include breakpoint(tablet) {
      margin: 0 auto 10px;
    }

    @include breakpoint(large) {
      margin-bottom: 20px;
    }

    @include breakpoint(xlarge) {
      width: 70px;
      height: 70px;
      margin-bottom: 30px;
    }

    [data-icon] {
      max-width: 50%;
      max-height: 60%;
    }
  }

  &__title {
    @include font-serif;
    font-size: 18px;
    color: $secondary-color;
    line-height: 1.3;
    text-align: left;

    @include breakpoint(tablet) {
      text-align: center;
    }

    @include breakpoint(xlarge) {
      font-size: 22px;
    }

    @include breakpoint(xxlarge) {
      font-size: 25px;
    }

    strong {
      display: block;
      font-style: italic;
    }
  }
}

.glance-map-inner {
  position: relative;
  padding: 30px 0;

  @include breakpoint(tablet) {
    display: flex;
    justify-content: space-between;
    padding: 45px 0;
  }

  @include breakpoint(large) {
    justify-content: flex-end;
    padding: 60px 0;
    min-height: 540px;
  }

  @include breakpoint(xlarge) {
    min-height: 620px;
    padding: 80px 0;
  }

  @include breakpoint(xxlarge) {
    min-height: 760px;
    padding: 120px 0;
  }
}

.glance-map {
  position: relative;

  @include breakpoint(medium down) {
    margin-bottom: 30px;
  }

  @include breakpoint(tablet) {
    width: calc(50% - 20px);
    flex-shrink: 0;
    margin-right: 20px;
  }

  @include breakpoint(large) {
    width: 60%;
    margin: 0;
    position: absolute;
    top: 30px;
    left: 0;
  }

  @include breakpoint(xlarge) {
    top: -50px;
  }

  @include breakpoint(xxlarge) {
    width: 900px;
    top: -70px;
  }

  &:before {
    content: '';
    padding-top: percentage(733 / 928);
    display: block;
  }

  svg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.glance-map-content {
  @include breakpoint(large) {
    width: 450px;
  }

  @include breakpoint(xxlarge) {
    width: 570px;
  }

  &__title {
    @include rfs(50);
    font-weight: 300;

    strong {
      display: block;
    }
  }
}
