.story-items {
  @include list-reset;
  padding: 30px 0;

  @include breakpoint(tablet) {
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 60px;
    margin: -60px -10px 0;
  }

  @include breakpoint(xxlarge) {
    margin: -60px -20px 0;
  }
}

.story-item {
  $self: &;
  margin-bottom: 20px;
  position: relative;
  z-index: 5;

  @include breakpoint(tablet) {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
  }

  @include breakpoint(large) {
    width: percentage(1 / 3);
  }

  @include breakpoint(xxlarge) {
    padding: 0 15px;
    margin-bottom: 30px;
  }

  &__block {
    border: 5px solid $white;
    border-radius: 6px;
    box-shadow: 0 2px 10px 0 rgba(black, 0.4);
    display: block;
    position: relative;
    transition: box-shadow 0.2s ease-in-out;
    height: 100%;

    &:before {
      content: '';
      background-image: linear-gradient(to bottom, transparent, rgba(black, 0.6));
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      transition: opacity 0.2s ease-in-out;
    }

    &:hover,
    &:focus {
      box-shadow: 0 2px 15px 0 rgba(black, 0.5);

      &:before {
        opacity: 0.7;
      }

      #{$self}__arrow [data-icon] {
        margin-left: 1.3em;
      }
    }
  }

  &__title {
    @include rfs(35);
    color: $white;
    position: absolute;
    padding: 20px;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  &__program {
    @include rfs(25);
    text-transform: uppercase;
    line-height: 1.1;
    position: absolute;
    bottom: 15px;
    left: 15px;
    color: $white;
    width: calc(100% - 120px);

    img {
      max-width: 120px;
      margin: auto;
    }
  }

  &__arrow {
    background: $accent-color;
    width: 90px;
    height: 60px;
    border-top-left-radius: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    position: absolute;
    bottom: 0;
    right: 0;

    [data-icon] {
      transition: all 0.2s ease-in-out;
      margin-left: 1em;
    }
  }
}
