%base-btn {
    display: block;
    width: 100%;
    border: 2px solid $accent-color;
    border-radius: 35px;
    color: $accent-color;
    text-transform: uppercase;
    font-weight: bold;
    padding: 15px 25px;
    text-align: center;
    transition: all 0.2s ease-in-out;
    line-height: inherit;

    @include breakpoint(tablet) {
        max-width: 285px;
    }

    @include breakpoint(xlarge) {
        padding: 17px 30px;
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.btn {
    @extend %base-btn;

    &:not(&--fill):not(:disabled):not(.is-loading):hover,
    &:not(&--fill):not(:disabled):not(.is-loading):focus {
        background: $accent-color;
        border-color: $accent-color;
        color: $white;
    }

    &.is-loading {
        opacity: 1;
        color: transparent;
        border-color: transparent;
        cursor: progress;
    }

    &--small {
        border-radius: 8px;
        padding: 8px 15px 6px;
        font-size: 15px;
        width: auto;
    }

    &--light {
        border-color: $white;
        color: $white;
    }

    &--link {
        border: none;
        color: $dark-blue;
        background: transparent;

        &:not(.btn--fill):not(:disabled):not(.is-loading):hover {
            border: none;
            background-color: transparent;
            color: lighten($dark-blue, 10);
        }
    }

    .cta-group--light & {
        border-color: $white;
        color: $white;
    }
}

.btn--fill {
    @extend %base-btn;
    border-color: $accent-color;
    background: $accent-color;
    color: #013752;

    &:hover:not(:disabled):not(.is-loading),
    &:focus:not(:disabled):not(.is-loading) {
        background: lighten($accent-color, 10%);
        border-color: lighten($accent-color, 10%);
        color: $white;
    }

    &.is-loading {
        background: $accent-color;
        color: $accent-color;
        cursor: progress;
        opacity: 0.8;
    }
}
