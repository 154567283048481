.element-wraparoundprocess {
  @include panel-padding;
  background: $primary-color;
  color: $white;

  @include breakpoint(tablet) {
    padding-bottom: 90px;
  }

  @include breakpoint(large) {
    padding-bottom: 120px;
  }

  @include breakpoint(xlarge) {
    padding-bottom: 150px;
  }
}

.process-items {
  @include breakpoint(xlarge) {
    margin: 40px 0 60px;
  }

  .grid-x {
    justify-content: center;
  }

  .cell {
    @include breakpoint(xlarge) {
      @include cell-borders(rgba(white, 0.3));
    }
  }
}

.process-item {
  padding: 20px 0;
  position: relative;

  @include breakpoint(xlarge) {
    padding: 20px;
  }

  @include breakpoint(xxlarge) {
    padding: 20px 40px;
  }

  &__top {
    display: flex;
    align-items: center;
    margin-bottom: 0.75em;

    [data-icon] {
      flex-shrink: 0;
      margin-right: 1em;
    }
  }

  &__title {
    @include rfs(35)
    color: $white;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 1;
    margin: 0;

    strong {
      @include rfs(45)
      display: block;
    }
  }

  &__arrow {
    display: none;

    @include breakpoint(xlarge) {
      display: block;
      background: $white;
      color: $secondary-color;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 110px;
      right: -40px;
    }
  }

  [data-icon="checkmark-circle"] {
    @include icon-sizer(checkmark-circle, auto, 58px);
  }
}
