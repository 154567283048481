.element-testimonialslider {
  position: relative;
  z-index: 2;
  padding: 32px 0;

  @include breakpoint(tablet) {
    padding: 0 0 64px;
  }

  @include breakpoint(xlarge) {
    padding-bottom: 96px;
  }

  @include breakpoint(xxlarge) {
    font-size: 22px;
  }

  .panel-top {
    text-align: center;
  }

  .headline {
    line-height: 1.1;

    strong {
      display: block;
      font-style: italic;
      font-size: 2.2em;
    }
  }

  .panel-content {
    font-weight: bold;

    @include breakpoint(tablet) {
      padding: 0 32px;
    }

    @include breakpoint(large) {
      padding: 0 64px;
    }

    @include breakpoint(xlarge) {
      padding: 0 128px;
    }
  }

  .testimonial-slider {
    margin-top: 48px;

    @include breakpoint(large) {
      margin: 64px 64px 0;
    }

    @include breakpoint(xlarge) {
      margin-top: 96px;
    }

    .flickity-page-dots {
      position: relative;
      top: 5px;

      .dot {
        border-color: $blue;
        background: transparent;

        @include breakpoint(large) {
          width: 20px;
          height: 20px;
        }

        &.is-selected {
          background: $blue;
        }
      }
    }
  }

  .testimonial-item {
    text-align: left;

    @include breakpoint(tablet) {
      display: flex;
      align-items: flex-start;
    }

    @include breakpoint(large) {
      padding: 0 32px;
    }

    &__image {
      width: 180px;
      height: 180px;
      display: block;
      margin: 0 auto;
      flex-shrink: 0;

      @include breakpoint(tablet) {
        width: 300px;
        height: 300px;
      }

      @include breakpoint(xlarge) {
        width: 380px;
        height: 380px;
      }

      @include breakpoint(xxlarge) {
        width: 440px;
        height: 440px;
      }

      img {
        border-radius: 100%;
      }
    }

    &__content {
      @include breakpoint(tablet) {
        flex: 1;
        padding-left: 32px;
      }

      @include breakpoint(xlarge) {
        padding-left: 64px;
      }
    }

    &__quote {
      font-style: italic;

      svg {
        width: 45px;
        margin-bottom: 24px;

        @include breakpoint(xlarge) {
          width: 75px;
        }
      }
    }

    &__quotee {
      margin-top: 32px;
    }

    &__person {
      font-weight: bold;
    }
  }
}
