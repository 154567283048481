.pagetype-publicrelationspage {
  .page-header__title {
    margin-bottom: 0.1em;
  }

  .page-header__lead-in {
    @include font-serif;
    font-weight: 600;
    font-style: italic;
    font-size: 24px;
    line-height: 1.3;
    margin: 0;

    @include breakpoint(tablet) {
      font-size: 30px;
    }

    @include breakpoint(large) {
      font-size: 40px;
    }
  }

  .page-header__inner {
    @include breakpoint(xlarge) {
      min-height: 360px;
    }

    .cta.btn--video {
      color: $gold;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .intro-blurb {
    position: relative;
    z-index: 1;
    margin-top: -64px;
    margin-bottom: 24px;

    @include breakpoint(large) {
      margin-top: -96px;
      margin-bottom: 0;
    }
  }

  .intro-blurb-container {
    padding: 24px;
    background: white;
    text-align: center;
    border-radius: 20px;
    box-shadow: 0 2px 20px 0 #4a4543;
    margin: auto;
    max-width: 1160px;

    @include breakpoint(tablet) {
      width: 95%;
      padding: 36px;
    }

    @include breakpoint(large) {
      padding: 36px 64px;
    }

    @include breakpoint(xxlarge) {
      font-size: 22px;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  .intro-blurb-headline {
    font-weight: bold;
    color: $blue;
    font-size: 1.2em;
    line-height: 1.3;
    margin-bottom: 0.3em;
  }
}
