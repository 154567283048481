.event-overview {
  &__thumbnail {
    max-width: 430px;
    margin-left: auto;

    @include breakpoint(tablet) {
      margin-top: -90px;
    }

    @include breakpoint(xlarge) {
      margin-top: -120px;
    }

    img {
      border: 6px solid $white;
      border-radius: 8px;
    }

    .btn {
      margin: 20px auto 0;
      max-width: 240px;
    }
  }

  &__title {
    @include rfs(55);
  }

  &__content {
    @include rfs(20);
  }
}

.event-checkmarks {
  padding: 20px 0;

  &__title {
    @include rfs(25);
  }

  &__items {
    @include list-reset;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
  }

  [data-icon] {
    color: $secondary-color;
    margin-right: 10px;
    flex-shrink: 0;
    position: relative;
    top: 2px;

    @include breakpoint(xlarge) {
      top: 3px;
    }
  }
}

.event-details {
  position: relative;
  z-index: 1;
  background-color: $primary-color;
  color: $white;

  @include breakpoint(tablet) {
    padding-bottom: 15vw;
  }

  @include breakpoint(large) {
    margin-top: 60px;
  }

  @include breakpoint(xlarge) {
    margin-top: 75px;
  }

  @include breakpoint(xxlarge) {
    margin-top: 100px;
    min-height: 430px;
  }

  &:after {
    @include breakpoint(tablet) {
      content: '';
      background: $white;
      width: 105%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 50%;
      height: 15vw;
      top: calc(100% - 7vw);
    }
  }

  .bg-icon-wrapper {
    background-repeat: no-repeat;
    background-position: center top;
    background-size: contain;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) !important;
    width: 100%;
    padding-top: percentage(323 / 1062);
    max-width: 1060px;
    z-index: 0;
  }

  &__blocks {
    text-align: center;

    @include breakpoint(tablet) {
      display: flex;
      justify-content: center;
    }
  }

  &__block {
    padding: 30px 0;
    position: relative;

    @include breakpoint(tablet) {
      width: percentage(1 / 3);
    }

    @include breakpoint(large) {
      padding: 0 30px 30px;
    }

    &:before {
      @include breakpoint(large) {
        content: '';
        width: 300px;
        height: 300px;
        background: $primary-color;
        border-radius: 50%;
        position: absolute;
        top: -60px;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
      }

      @include breakpoint(xlarge) {
        width: 350px;
        height: 350px;
        top: -75px;
      }

      @include breakpoint(xxlarge) {
        width: 420px;
        height: 420px;
      }
    }

    [data-icon] {
      @include breakpoint(large) {
        margin-bottom: 20px;
      }
    }

    &:not(:first-child):after {
      @include breakpoint(large) {
        content: '';
        height: 70%;
        position: absolute;
        left: 0;
        top: 30px;
        border-left: 1px solid $white;
      }
    }
  }

  &__inner {
    @include breakpoint(large) {
      margin-top: -30px;
    }

    @include breakpoint(xlarge) {
      margin-top: -40px;
    }
  }

  &__title {
    @include font-serif;
    @include rfs(45);
    color: $white;
    font-weight: normal;
    line-height: 1;
    margin-bottom: 0.5em;

    strong {
      font-style: italic;
    }
  }

  &__instances {
    @include list-reset;
  }

  &__instance {
    margin-bottom: 10px;
  }

  &__date-label {
    @include rfs(18);
    text-transform: uppercase;
    font-weight: bold;
  }

  &__time {
    display: block;

    @include breakpoint(xxlarge) {
      display: inline-block;
      border-left: 1px solid $white;
      margin-left: 5px;
      padding-left: 10px;
      line-height: 1;
    }
  }

  [data-icon="calendar-checkmark"] {
    @include icon-sizer(calendar-checkmark, auto, 48px);

    @include breakpoint(xlarge) {
      @include icon-sizer(calendar-checkmark, auto, 66px);
    }
  }

  [data-icon="dollar-sign"] {
    @include icon-sizer(dollar-sign, auto, 48px);

    @include breakpoint(xlarge) {
      @include icon-sizer(dollar-sign, auto, 66px);
    }
  }

  [data-icon="map-pin"] {
    @include icon-sizer(map-pin, auto, 48px);

    @include breakpoint(xlarge) {
      @include icon-sizer(map-pin, auto, 66px);
    }
  }

  &__content {
    @include rfs(25);
  }

  .cost-content {
    text-transform: uppercase;
  }

  &__tickets {
    margin-top: 20px;

    .event-ticket-form-trigger {
      margin: 30px auto auto;
    }

    .sold-out {
      @include rfs(25);
    }

    .sold-out-message-wrapper {
      @include rfs(18);
      text-align: center;

      .btn {
        margin: 20px auto;
        width: auto;
        display: inline-block;
      }
    }
  }
}

.event-additional {
  @include panel-padding;
  position: relative;
  z-index: 1;
  text-align: center;

  @include breakpoint(xlarge) {
    margin-top: -50px;
  }

  &__title {
    @include rfs(55);
  }

  &__items {
    @include list-reset;
    max-width: 920px;
    margin: auto;
  }

  &__item {
    position: relative;

    &:first-child {
      padding-bottom: 30px;

      @include breakpoint(large) {
        padding-bottom: 40px;
      }
    }

    &:not(:first-child) {
      border-top: 1px solid $light-gray;
      padding: 50px 0 30px;

      @include breakpoint(large) {
        padding: 60px 0 40px;
      }

      [data-icon] {
        background: $white;
        color: $secondary-color;
        width: 25px;
        height: 23px;
        position: absolute;
        top: -12px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    a.ft-pdf,
    a[href$=".pdf"]:not(.btn):not(.ignore-ft) {
      display: inline-block;

      &:before {
        display: inline-block;
        top: 3px;
        height: 1.2em;
        width: 1.2em;
        margin-right: 0.4em;
      }
    }
  }

  &__item-content {
    @include breakpoint(tablet) {
      @include rfs(25);
    }
  }

  strong {
    color: $primary-color;
  }
}

.event-sponsors {
  @include panel-padding($top: false);
  position: relative;
  z-index: 1;

  &--top-padding {
    @include panel-padding;
  }

  &__inner {
    box-shadow: 0 4px 6px 1px rgba(black, 0.3);
    padding: 20px;
    text-align: center;
  }

  &__block {
    padding: 20px 0;

    @include breakpoint(large) {
      padding: 40px 20px;
    }
  }

  &__title {
    @include rfs(35);
    @include font-serif;
    font-weight: normal;

    em {
      font-weight: bold;
    }
  }

  &__items {
    @include list-reset;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  &__item {
    padding: 10px 20px;
  }

  &__link {
    display: block;

    &:hover,
    &:focus {
      opacity: 0.7;
    }
  }
}

.event-photos {
  @include panel-padding;
}
