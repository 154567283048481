.pagetype-ourstorypage {
  .page-header {
    &__left {
      max-width: 462px;
      margin: auto;
      width: 50vw;
    }

    &__title {
      @include text-hide;
      margin: 0;
      height: 0;
      background: url(~images/our-story-title.svg) no-repeat center / cover;
      padding-top: percentage(215 / 462);
    }
  }
}
