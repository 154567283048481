.lead-in-items {
  @include breakpoint(tablet) {
    display: flex;
    margin: 0 -15px;
    margin-bottom: 30px;
    flex-wrap: wrap;
  }

  @include breakpoint(large) {
    flex-wrap: nowrap;
  }
}

.lead-in-item {
  margin: 20px 0;

  @include breakpoint(tablet) {
    width: percentage(1 / 3);
    padding: 0 15px;
  }

  &__link {
    display: block;
    background: $white;
    box-shadow: 0 2px 10px 0 rgba(black, 0.4);
    border-radius: 6px;
    padding: 20px;
    text-align: center;
    color: $primary-color;
    font-weight: 300;
    height: 100%;
    position: relative;
    transition: box-shadow 0.2s ease-in-out;

    @include breakpoint(tablet) {
      padding-bottom: 60px;
    }

    @include breakpoint(large) {
      padding: 40px 20px 60px;
    }

    @include breakpoint(xlarge) {
      padding: 60px 30px;
      min-height: 420px;
    }

    @include breakpoint(xxlarge) {
      padding: 60px 50px;
    }

    &:hover,
    &:focus {
      box-shadow: 0 2px 15px 0 rgba(black, 0.5);

      [data-icon="arrow"] {
        @include breakpoint(medium down) {
          transform: scale(0.6) translateX(4px);
        }

        transform: translateX(4px);
      }
    }
  }

  &__border-box {
    border: 1px solid #C9BFBF;
    box-shadow: unset;
  }

  &__icon {
    [data-icon] {
      width: auto;
      height: 70px;

      @include breakpoint(large) {
        height: 100px;
      }
    }
  }

  &__card-image {
    width: 100%;
    height: 130px;
    object-fit: contain;
    object-position: bottom;
    margin-bottom: 40px;
  }

  &__small-icon {
    [data-icon] {
      width: auto;
      height: 50px;
    }


    @include breakpoint(large) {
        svg[data-icon="heart-hands"] {
            height: 60px !important;
            margin-bottom: -10px;
        }

        svg[data-icon="mail"] {
            height: 2.8rem !important;
        }

    }

    +.lead-in-item__title {
        margin-top: 15px;
        font-size: 50px;
    }
  }

  &__green-icon {
    [data-icon] {
      color: #3f8227;
    }
  }

  &__title {
    @include font-serif;
    @include rfs(55);
    line-height: 1;
    margin: 0.5em 0;

    strong {
      font-style: italic;
    }
  }

  &__lead-in {
    color: $black;
  }

  &__left-aligned {
    text-align: left !important;
  }

  &__bolder {
    font-weight: 400;
  }

  &__arrow {
    background: $accent-color;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    border-radius: 50%;
    margin: 20px auto 0;

    @include breakpoint(tablet) {
      position: absolute;
      bottom: -35px;
      left: 50%;
      transform: translateX(-50%);
      margin: 0;
      width: 70px;
      height: 70px;
    }

    [data-icon] {
      transition: transform 0.2s ease-in-out;

      @include breakpoint(medium down) {
        transform: scale(0.6);
      }
    }
  }

  &__link-title {
    background: $accent-color;
    width: max-content;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    border-radius: 50px;
    margin: 20px auto 0;

    @include breakpoint(tablet) {
      position: absolute;
      bottom: -25px;
      left: 50%;
      transform: translateX(-50%);
      margin: 0;
      width: max-content;
    }

    [data-icon] {
      transition: transform 0.2s ease-in-out;

      @include breakpoint(medium down) {
        transform: scale(0.6);
      }
    }
  }

  .cta.btn {
    min-width: auto;
  }

  .btn {
    background-color: #fff;
    color: #007ab8;
    border-color: #007ab8;
  }
}
