.pagetype-annualreportpage {
    .past-reports {
        padding: 1rem 0;

        &:not(.no-pad) {
            @include breakpoint(tablet) {
                padding: 2rem 0;
            }

            @include breakpoint(large) {
                padding: 2.5rem 0;
            }

            @include breakpoint(xlarge) {
                padding: 2.5rem 0 2rem;
            }
        }

        &__list {
            list-style-type: none;
            margin: 0;
            padding: 0;
            display: grid;
            grid-template-columns: minmax(320px, 1fr);
            gap: 1rem;

            @include breakpoint(medium) {
                grid-template-columns: minmax(320px, 1fr) minmax(320px, 1fr);
                gap: 1.5rem 1rem;
            }

            @include breakpoint(large) {
                grid-template-columns: minmax(320px, 1fr) minmax(320px, 1fr) minmax(320px, 1fr);
            }

            @include breakpoint(xxlarge) {
                grid-template-columns: minmax(320px, 1fr) minmax(320px, 1fr) minmax(320px, 1fr) minmax(320px, 1fr);
                gap: 2.5rem 2rem;
            }
        }
        &__list-item {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            &:not(:last-child) {
                &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 1px;
                    background-color: #00000033;
                }
            }

            @include breakpoint(medium) {
                &::after {
                    display: none;
                    visibility: hidden;
                }
            }

            .image-link {
                display: none;

                @include breakpoint(medium) {
                    display: block;
                }

                &:hover {
                    opacity: 0.8;
                }
            }
            &--image {
                object-fit: contain;
            }
            &--title {
                margin: 0.5em 0;
            }
            &--lead-in {
                margin: 0.5em 0;
            }

            &--actions {
                margin: 1rem 0;
            }

            .btn {
                display: flex;
                justify-content: center;
                align-items: center;
                max-width: 100%;
                line-height: 1;

                &::before {
                    transform: translate3d(-0.5rem 0 0);
                }

                & + * {
                    margin-top: 1rem;
                }
            }

            .download-link {
                color: $dark-blue;
                transition: all 0.3s ease-in-out;

                &:hover {
                    color: lighten($dark-blue, 15);
                }
            }
        }
    }
}
