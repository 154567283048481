.element-latestnews {
  @include panel-padding;

  .panel-title {
    margin-bottom: 0.75em;
  }
}

.latest-news-slider {
  @include breakpoint(large) {
    max-width: calc(100% - 120px);
    margin: auto;
  }

  @include breakpoint(xlarge) {
    max-width: calc(100% - 160px);
  }
}

.latest-news-slide {
  padding: 10px;

  @include breakpoint(tablet) {
    display: flex;
    min-height: 100%;
  }

  .news-item {
    width: 100%;

    @include breakpoint(tablet) {
      min-height: 100%;
      margin: 0;
    }
  }
}

.latest-news-actions {
  padding-top: 30px;

  .btn {
    margin: auto;
  }
}
