.pagetype-contactpage, .pagetype-officepage {
  .page-container {
    @include panel-padding($top: false);
  }
}

.contact-content {
  position: relative;

  @include breakpoint(large) {
    min-height: 860px;
  }

  @include breakpoint(xlarge) {
    padding-right: 60px;
  }

  @include breakpoint(xxlarge) {
    padding-right: 80px;
  }

  &:after {
    @include breakpoint(large) {
      content: '';
      height: 95%;
      width: 1px;
      background: $medium-gray;
      position: absolute;
      right: -45px;
      top: 30px;
    }

    @include breakpoint(xlarge) {
      right: 0;
    }
  }

  &__inner {
    margin-bottom: 30px;
  }
}

.contact-sidebar {
  margin-top: 60px;

  @include breakpoint(tablet) {
    margin-top: 30px;
  }

  @include breakpoint(large only) {
    padding-left: 40px;
  }

  &__logo {
    width: 185px;
  }

  &__details {
    @include rfs(25);
    margin: 2.5rem 0;

    dt {
      display: flex;
      align-items: center;
      color: $accent-color;
      font-weight: bold;
      line-height: 1;
      margin-bottom: 0.1em;

      span {
        display: inline-flex;
        width: 24px;
        height: 24px;
        background: $accent-color;
        color: $white;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
      }

      [data-icon] {
        width: 50%;
        height: 50%;
      }

      a.office-location {
        @include anchor-hover($blue, $green);
        display: flex;
      }

      &.header {
        color: $blue;
      }

    }

    dd {
      margin: 0 0 2rem;
      padding-left: 40px;

      &:last-child {
        margin: 0;
      }

      a {
        @include anchor-hover($black);
        font-weight: 300;
      }

      &.phone {
        a:hover {
            color: $green;
        }
      }
    }

    #office-location-map {
        height: 400px;
        margin-bottom: 2rem;
    }

    .sidebar-offices {
        padding: 0;
        list-style-type: none;

        li:not(:last-child) {
            margin-bottom: 1rem;
        }

        a {
            display: flex;
            align-items: baseline;
            font-size: 1.25rem;
            line-height: 1.25;

            span {
                display: block;
            }

            .icon {
                display: inline-flex;
                width: 24px;
                height: 24px;
                min-width: 24px;
                min-height: 24px;
                background: $accent-color;
                color: $white;
                border-radius: 50%;
                align-items: center;
                justify-content: center;
                margin-right: 16px;
            }

            [data-icon] {
                width: 50%;
                height: 50%;
            }

            .label {
                @include anchor-hover($green, $blue);
            }
        }

    }

  }
}
