.element-ipaoverview {
  .page-container {
    @include breakpoint(large) {
      margin-bottom: 40px;
    }

    @include breakpoint(xxlarge) {
      margin-bottom: 60px;
    }

    &__inner {
      @include breakpoint(large) {
        display: flex;
      }
    }
  }
}

.ipa-overview-content {
  @include breakpoint(large) {
    padding-right: 40px;
  }

  @include breakpoint(xxlarge) {
    padding-right: 60px;
  }

  p:first-child strong {
    @include rfs(25);
  }
}

.ipa-overview-sidebar {
  margin-top: 30px;

  @include breakpoint(large) {
    background: $white;
    border-radius: 6px;
    box-shadow: 0 2px 10px 0 rgba(black, 0.15);
    padding: 60px 30px;
    width: 420px;
    margin-top: -80px;
    margin-right: -40px;
    flex-shrink: 0;
  }

  @include breakpoint(xlarge) {
    margin-right: -60px;
  }

  @include breakpoint(xxlarge) {
    width: 540px;
    padding: 60px;
    margin-top: -90px;
  }

  &__title {
    @include rfs(25);
    color: $secondary-color;
  }

  &__items {
    @include list-reset;
    @include rfs(25);

    li {
      display: flex;
      align-items: baseline;
      margin: 10px 0;
    }
  }

  [data-icon] {
    color: $secondary-color;
    margin-right: 10px;
    position: relative;
    top: 2px;
    flex-shrink: 0;
  }
}
