.pagetype-waystogivepage {
  .page-header__title {
    text-align: center;
    margin-bottom: 0;
    text-shadow: 1px 1px 2px rgba(black, 0.7);
  }

  .page-header__lead-in {
    text-align: center;
    margin: 0 auto 1rem auto;
    @include font-serif;
    @include rfs(40);
    font-weight: normal;
    text-shadow: 1px 1px 2px rgba(black, 0.7);
  }

  .page-header__subcopy {
    text-align: center;
    max-width: 52rem;
    margin: 0 auto;
    @include rfs(20);
    text-shadow: 1px 1px 2px rgba(black, 0.7);
  }

  .page-header__ctas {
    justify-content: center;

    .btn--fill:focus {
      border-color: #f2c744;
      background: #f2c744;
      color: #013752;
    }
    .btn:not(.btn--fill):focus {
      border-color: #fefefe;
      color: $blue;
      background: #fefefe;
    }

  }
}

.element-waystogive {
  @include panel-padding;

  padding-top: 2rem !important;

  @include breakpoint(large) {
    //display: flex;
  }
}

.wtg-content-wrapper {
  flex-shrink: 0;

  @include breakpoint(large) {
    width: 420px;
  }

  @include breakpoint(xlarge) {
    width: 520px;
  }

  @include breakpoint(xxlarge) {
    margin-left: calc((100% - #{$global-width}) / 2);
    padding-right: 20px;
  }
}

.wtg-title {
  @include rfs(55);
}

.wtg-slider-wrapper {
  overflow: hidden;

  @include breakpoint(tablet only) {
    margin: 30px 0 30px 15px;
  }

  @include breakpoint(large) {
    margin-top: -140px;
  }

  @include breakpoint(xxlarge) {
    margin-top: -180px;
  }
}

.wtg-controls {
  padding: 30px 0;
}

.wtg-arrows {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  @include breakpoint(tablet) {
    margin-bottom: 30px;
  }
}

.wtg-arrow {
  margin: 0 20px;
  cursor: pointer;
  color: $dark-gray;

  @include breakpoint(tablet) {
    margin: 0 30px;
  }

  &:hover,
  &:focus {
    color: $accent-color;
  }

  [data-icon] {
    width: 19px;
    height: 35px;

    @include breakpoint(tablet) {
      width: 28px;
      height: 52px;
    }
  }

  &--prev {
    transform: scale(-1, 1);
  }
}

.wtg-dots {
  display: flex;
  justify-content: center;

  button {
    text-indent: -999em;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background: $light-gray;
    margin: 0 5px;

    &.is-selected {
      background: $accent-color;
    }
  }
}

.wtg-slider {
  display: flex;
  align-items: center;
  left: -100%;
  position: relative;
  transform: translateX(100%);

  @include breakpoint(tablet) {
    left: -375px;
    transform: translateX(375px);
  }

  &.is-reversing {
    transform: translateX(-100%);

    @include breakpoint(tablet) {
      transform: translateX(-375px);
    }
  }

  &.is-set {
    transform: none;
    transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  }
}

.wtg-slide {
  $self: &;
  width: 100%;
  border-radius: 5px;
  border: 5px solid $white;
  flex-shrink: 0;
  order: 2;
  cursor: pointer;

  @include breakpoint(tablet) {
    width: 375px;
  }

  @include breakpoint(large) {
    border-width: 15px;
  }

  &.is-ref {
    order: 1;
  }

  &__image {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    img {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  &__title {
    @include font-serif;
    @include rfs(25);
    font-weight: normal;
    color: $white;
    margin-bottom: 0;
    text-align: center;

    strong {
      font-style: italic;
    }
  }

  &__details {
    background: $primary-color;
    color: $white;
    padding: 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &__content {
    margin-top: 0.5em;
    display: none;
  }

  &.is-selected {
    cursor: default;

    @include breakpoint(tablet) {
      width: 480px;
    }

    @include breakpoint(xxlarge) {
      width: 540px;
    }

    #{$self}__title {
      text-align: left;
      font-size: 26px;

      @include breakpoint(tablet) {
        font-size: 40px;
      }

      @include breakpoint(xxlarge) {
        font-size: 48px;
      }
    }

    #{$self}__details {
      @include breakpoint(large) {
        padding: 25px 30px;
      }

      @include breakpoint(xxlarge) {
        padding: 30px 40px;
      }
    }

    #{$self}__content {
      display: block;
    }

    #{$self}__text {
      @include breakpoint(tablet) {
        font-size: 20px;
      }
    }
  }

  .cta-group {
    padding-bottom: 20px;
  }
}

.wtg-header-lead-in {
  text-align: center;
  color: $blue;
  font-weight: bold;
  margin-bottom: 2rem;
}

.wtg-grid {

  display: grid;
  grid-column-gap: 1.5rem;
  grid-row-gap: 5.5rem;

  @include breakpoint(tablet) {
    grid-template-columns: 1fr 1fr;
  }

  @include breakpoint(xlarge) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  margin-bottom: 7rem;

  .wtg-grid-item {

    border: 1px solid #dcdcdc;
    border-radius: .75rem;
    position: relative;

    &__image {
      img {
        width: 100%;
        border-top-left-radius: .75rem;
        border-top-right-radius: .75rem;
      }
    }

    &__details {
      padding: 2rem 2rem 4rem 2rem;
    }

    &__title {
      @include font-serif;
      @include rfs(36);
      line-height: 1;
      font-weight: normal;
      color: $blue;
      margin-bottom: 0;

      strong {
        font-style: italic;
      }
    }

    &__content {
      margin-top: 1rem;
    }

    &__cta-wrap {
      position: absolute;
      bottom: -2rem;
      left: 0;
      right: 0;
      text-align: center;
      max-width: 70%;
      margin: 0 auto;

      @include breakpoint(tablet) {
        max-width: 100%;
      }

      .btn {
        background-color: #fff;
        color: $blue;
        border-color: $blue;
      }

    }

  }

}

.wtg-donate-by-check {

  border: 1px solid #dcdcdc;
  border-radius: .75rem;
  padding: 2rem;


  @include breakpoint(tablet) {
    display: flex;
    align-items: center;
    align-content: stretch;
    justify-content: center;
  }


  &__header {

    text-align: center;
    margin-bottom: .75rem;

    @include breakpoint(tablet) {
      border-right: 1px solid #dcdcdc;
      text-align: right;
      margin-bottom: 0;
    }

    h2 {

      margin-bottom: 0;

      @include breakpoint(tablet) {
        margin-right: 2.5rem;
      }
    }

  }

  &__content {

    p {
      margin-bottom: 0;

      @include breakpoint(tablet) {
        margin-left: 2.5rem;
      }
    }

  }

}
