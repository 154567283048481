.element-photogalleries {

    padding-top: 2rem;
    padding-bottom: 2rem;

    @include breakpoint(large) {
      padding-top: 4rem;
      padding-bottom: 4rem;

    }

    h2 {
        text-align: center;
    }

    .photo-galleries {
        display: grid;
        gap: 2rem;

        @include breakpoint(medium) {
            grid-template-columns: 1fr 1fr;
        }

        @include breakpoint(large) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        .gallery {


            .gallery-link {

                img {
                    transition: transform 0.4s;
                }

                &:hover {
                    color: $green;

                    img {
                        transform: scale(1.05);
                    }

                }

                .gallery-title {
                    margin-top: 0.5rem;
                    text-align: center;
                    font-weight: bold;
                }

            }


            .gallery-description {
                margin-top: 0.5rem;
                padding-left: 1rem;
                padding-right: 1rem;
                font-size: 0.875rem;
                line-height: 1;

                @include breakpoint(large) {
                    font-size: 1rem;
                    line-height: 1.25;
                    margin-top: 1rem;
                }

            }


            .gallery-photos {
                display: none;
            }


        }

    }

}
