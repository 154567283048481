.element-programoverview {
  @include panel-padding;
}

.program-overview {
  @include breakpoint(tablet) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__sidebar {
    padding: 20px;
    box-shadow: 0 2px 10px 0 rgba(black, 0.3);
    border-radius: 6px;
    max-width: 520px;
    position: relative;
    z-index: 1;
    background: $white;

    @include breakpoint(medium down) {
      margin-top: 40px;
    }

    @include breakpoint(tablet) {
      width: 40%;
      flex-shrink: 0;
      margin-left: 40px;
    }

    @include breakpoint(large) {
      margin-top: -100px;
      padding: 30px;
    }

    @include breakpoint(xlarge) {
      margin-top: -120px;
      padding: 30px 45px;
    }

    @include breakpoint(xxlarge) {
      margin-top: -140px;
    }

    img {
      max-width: 220px;
      display: block;
      margin: 0 auto 30px;

      @include breakpoint(tablet down) {
        width: 150px;
      }
    }
  }
}

.program-overview-criteria {
  &__title {
    @include rfs(35);
    font-weight: normal;
    color: $secondary-color;
    margin-bottom: 0.35em;
  }

  &__items {
    @include list-reset;

    li {
      display: flex;
      align-items: baseline;
      margin: 10px 0;
    }

    p {
      margin: 0;
    }
  }

  [data-icon] {
    color: $secondary-color;
    margin-right: 10px;
    position: relative;
    top: 2px;
    flex-shrink: 0;
  }
}

.program-overview-referral {
  border-top: 1px solid $medium-gray;
  margin-top: 20px;
  padding-top: 20px;

  &__title {
    @include rfs(35);
    font-weight: normal;
    color: $secondary-color;
    margin-bottom: 0.35em;
  }
}
