.element-intro {
  padding: 2rem 0;

  @include breakpoint(tablet) {
    padding: 2rem 0;
  }

  @include breakpoint(xxlarge) {
    font-size: 20px;
  }

  .headline {
    text-align: center;
  }

  .block-one {
    @include breakpoint(tablet) {
      display: flex;
      margin-top: 64px;
    }

    .block-content {
      margin-top: 32px;

      @include breakpoint(tablet) {
        margin: 0;
        order: -1;
        width: 50%;
        flex-shrink: 0;
        padding-right: 24px;
      }

      @include breakpoint(xlarge) {
        padding: 24px 48px;
      }

      @include breakpoint(xxlarge) {
        padding: 36px 48px 36px 0;
      }

      @include breakpoint(1596px) {
        position: relative;
        left: -48px;
      }
    }

    .block-image {
      margin: 0 -10px;

      @include breakpoint(tablet) {
        width: calc(100% + (50vw - 50%));
        margin-left: 0;
        margin-right: calc(50% - 50vw);
      }
    }
  }

  .bullets-list {
    @include list-reset;
    margin-top: 32px;

    li {
      display: flex;
      align-items: baseline;
      font-weight: bold;
      margin: 8px 0;

      @include breakpoint(xxlarge) {
        margin: 12px 0;
      }
    }

    svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      flex-shrink: 0;
      position: relative;
      top: 4px;

      @include breakpoint(xxlarge) {
        width: 24px;
        height: 24px;
        top: 5px;
      }
    }
  }

  .block-two {

    @include breakpoint(tablet) {
      display: flex;
      align-items: center;
    }

    @include breakpoint(xxlarge) {
    }

    .block-content {
      margin-top: 32px;

      @include breakpoint(tablet) {
        margin: 0;
        width: 50%;
        flex-shrink: 0;
        padding-left: 24px;
      }

      @include breakpoint(xlarge) {
        padding: 24px 48px;
      }

      @include breakpoint(xxlarge) {
        padding: 36px 48px;
      }

      @include breakpoint(xxlarge) {
        padding: 36px 0 36px 48px;
      }

      @include breakpoint(1596px) {
        position: relative;
        left: 48px;
      }
    }

    .block-image {
      margin: 0 -10px;

      @include breakpoint(tablet) {
        margin-right: 0;
        width: calc(100% + (50vw - 50%));
        margin-left: calc(50% - 50vw);
      }
    }

    .content-image {
      margin-top: 32px;
    }
  }

  .cta-group {

    .cta {
        font-size: 1.125rem;
    }

  }

}
