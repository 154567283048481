body {
  transition: transform 0.3s ease-in-out;
  font-weight: 300;
  overflow-x: hidden;

  @include breakpoint(xlarge) {
    font-size: 18px;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba($black, 0.3);
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
  }
}

.site-wrapper {
  min-height: 80vh;
  overflow: hidden;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.is-loading {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid $white;
    border-top-color: $primary-color;
    animation: spinner .6s linear infinite;
  }
}

.skip-link {
    position: absolute;
    z-index: 1000;
    left: 50%;
    font-size: 14px;
    background: $white;
    padding: 7px 10px;
    transform: translateX(-50%);
    top: -100%;

    &:focus {
      top: 0;
    }

    &:focus,
    &:active {
      border: 1px dotted $gold;
    }
  }

img.left {
  float: left;
  margin-right: 2rem;
  margin-bottom: 1rem;
}

.video-container { width: 100% !important; position: relative; padding-bottom: 56.25%; padding-top: 30px; height: 0; overflow: hidden; margin-bottom: 1rem; }

.video-container iframe, .video-container object, .video-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

