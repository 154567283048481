.element-largeimagecontent:not(.join-our-team) {
  position: relative;
  z-index: 2;
  padding: 32px 0;

  @include breakpoint(tablet) {
    padding: 64px 0;
  }

  @include breakpoint(xlarge) {
    padding: 96px 0;
  }

  @include breakpoint(xxlarge) {
    font-size: 22px;
  }

  &.as-with-content:not(.join-our-team) {
    @include breakpoint(tablet) {
      margin-bottom: -128px;
    }

    &:after {
      @include breakpoint(tablet) {
        content: '';
        background: white;
        border-radius: 50px;
        border: 1px solid white;
        width: calc(100% - 30px);
        height: 100%;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        pointer-events: none;
      }

      @include breakpoint(large) {
        width: calc(100% - 75px);
      }

      @include breakpoint(xxlarge) {
        width: calc(100% - 120px);
      }
    }

    .panel-top {
      position: relative;
      z-index: 1;
      text-align: center;
    }

    .headline {
      line-height: 1.2;
    }

    .panel-content {
      max-width: 1100px;
      margin: auto;
    }

    .panel-image {
      position: relative;
      z-index: 1;
      margin-top: 32px;

      @include breakpoint(tablet) {
        margin-top: 64px;
        padding: 0 30px;
      }

      @include breakpoint(large) {
        padding: 0 75px;
      }
    }
  }

  &.as-no-content {
    padding: 16px 0;
    margin-bottom: -72px;

    @include breakpoint(tablet) {
      padding: 16px 0;
      margin-bottom: -150px;
    }

    @include breakpoint(large) {
      margin-bottom: -172px;
    }

    @include breakpoint(xlarge) {
      margin-bottom: -200px;
    }

    @include breakpoint(xxlarge) {
      margin-bottom: -230px;
    }

    .panel-top {
      text-align: center;
    }

    .headline {
      line-height: 1.2;

      strong {
        display: block;
        font-style: italic;
        font-size: 2.2em;
      }
    }

    .panel-image {
      @include breakpoint(large) {
        padding: 0 64px;
      }

      @include breakpoint(xxlarge) {
        padding: 0 96px;
      }
    }
  }
}

.element-largeimagecontent {
    &.join-our-team {

        padding-top: 2rem;
        padding-bottom: 2rem;

        @include breakpoint(large) {
            padding-top: 4rem;
            padding-bottom: 4rem;
        }

        .headline {
            text-align: center;
        }

        .panel-image {

            img {
                width: 100%;
            }

        }
    }

    .panel-content {
      max-width: 65rem;
      margin: 0 auto;
    }

}
