.news-items {
  @include list-reset;

  @include breakpoint(tablet) {
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }

  @include breakpoint(xxlarge) {
    margin: 0 -20px;
  }
}

.news-item {
  margin-bottom: 20px;

  @include breakpoint(tablet) {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
  }

  @include breakpoint(large) {
    width: percentage(1 / 3);
  }

  @include breakpoint(xxlarge) {
    padding: 0 20px;
    margin-bottom: 40px;
  }

  &__wrapper {
    border: 5px solid $white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: block;
    box-shadow: 0 2px 10px 0 rgba(black, 0.4);

    @include breakpoint(tablet) {
      height: 100%;
      overflow: hidden;
    }

    &:hover .news-item__inner,
    &:focus .news-item__inner {
      background: lighten($secondary-color, 5%);
    }
  }

  &__thumbnail {
    position: relative;
    display: block;

    img {
      display: block;
    }
  }

  &__date {
    @include font-serif;
    @include rfs(25);
    display: inline-flex;
    align-items: flex-start;
    color: $white;
    font-weight: bold;
    margin-bottom: 15px;
    position: relative;

    span {
      width: 40px;
      height: 40px;
      color: $secondary-color;
      background: $white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      position: relative;
    }

    &:before {
      content: '';
      width: 90%;
      position: absolute;
      right: 0;
      bottom: calc(50% - 0.6em);
      border-bottom: 1px solid $white;
      z-index: 0;
    }
  }

  &__inner {
    background: $secondary-color;
    padding: 15px;
    color: $white;
    transition: background-color 0.2s ease-in-out;

    @include breakpoint(tablet) {
      height: 100%;
    }

    @include breakpoint(xxlarge) {
      padding: 20px;
    }
  }

  &__title {
    margin: 0;
    color: $white;
  }
}
