.element-imagecallout {
  padding: 32px 0;

  @include breakpoint(tablet) {
    padding: 64px 0;
  }

  @include breakpoint(xxlarge) {
    font-size: 22px;
  }

  .panel-wrapper {
    @include breakpoint(tablet) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  .panel-image {
    height: 192px;
    margin: 0 -10px;
    overflow: hidden;

    @include breakpoint(tablet) {
      width: 320px;
      height: 320px;
      border-radius: 100%;
      border: 5px solid white;
      order: 1;
    }

    @include breakpoint(large) {
      width: 420px;
      height: 420px;
      margin-top: -128px;
    }

    @include breakpoint(xlarge) {
      margin-top: -160px;
    }

    @include breakpoint(xxlarge) {
      width: 590px;
      height: 590px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .block-content {
    margin-top: 32px;

    @include breakpoint(tablet) {
      width: 50%;
      flex-shrink: 0;
    }
  }

  .headline {
    margin-bottom: 0.25em;
  }
}
