.resources-wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 30px 0;

  @include breakpoint(tablet) {
    padding: 45px 0;
  }

  @include breakpoint(large) {
    background-attachment: fixed;
  }

  @include breakpoint(xlarge) {
    padding: 60px 0;
  }

  @include breakpoint(xxlarge) {
    padding: 90px 0;
  }

  &__title {
    @include font-serif;
    @include rfs(200);
    text-align: center;
    font-style: italic;
    line-height: 0.69;
    margin: 0;
  }

  &__inner {
    border-radius: 5px;
    background: rgba(white, 0.7);
    position: relative;

    @include breakpoint(tablet down) {
      padding: 20px 0;
    }

    @include breakpoint(large) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    &.is-single {
      max-width: 920px;
      margin: auto;

      .resources-item {
        max-width: 690px;
        width: 100%;
      }
    }
  }
}

.resources-item {
  text-align: center;
  font-weight: normal;
  padding: 20px;

  @include breakpoint(tablet down) {
    max-width: 450px;
    margin: auto;
  }

  @include breakpoint(large) {
    width: 50%;
    padding: 40px;
  }

  @include breakpoint(xxlarge) {
    padding: 60px 90px;
  }

  &__title {
    @include font-serif;
    @include rfs(25);
    color: $black;
    font-style: italic;
    margin-bottom: 0.5em;
  }

  &__icon {
    margin-bottom: 1em;

    [data-icon] {
      color: rgba(black, 0.3);
      max-height: 80px;

      @include breakpoint(xlarge) {
        max-height: 130px;
      }
    }
  }

  .btn {
    margin: 30px auto 0;
  }
}
