.element-logogrid {
  background: $blue;
  position: relative;
  z-index: 1;
  padding: 32px 0;

  @include breakpoint(tablet) {
    padding: 192px 0 64px;
  }

  @include breakpoint(xlarge) {
    padding: 224px 0 96px;
  }

  @include breakpoint(xxlarge) {
    font-size: 22px;
  }

  &:after {
    content: '';
    background: white;
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150px;

    @include breakpoint(xlarge) {
      height: 200px;
    }
  }

  .panel-top {
    color: white;
    text-align: center;
  }

  .headline {
    color: white;
  }

  .panel-content {
    max-width: 940px;
    margin: auto;
    font-weight: bold;

    @include breakpoint(tablet) {
      width: 70%;
    }
  }

  .logo-grid {
    display: grid;
    grid-gap: 16px;
    margin-top: 32px;
    position: relative;
    z-index: 1;

    @include breakpoint(tablet) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include breakpoint(large) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      column-gap: 24px;
      row-gap: 36px;
      margin-top: 64px;
    }

    @include breakpoint(xxlarge) {
      row-gap: 48px;
    }
  }

  .logo-grid-item {
    background: white;
    padding: 24px;
    border: 1px solid $blue;
    border-radius: 20px;
    text-align: center;

    @include breakpoint(large) {
      padding: 24px 36px;
    }

    @include breakpoint(xxlarge) {
      padding: 24px 44px;
    }

    &__content {
      margin-top: 24px;

      p:last-child {
        margin: 0;
      }
    }
  }
}
