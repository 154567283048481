.js-toggle-trigger {
  .show-text {
    display: inline;
  }

  .hide-text {
    display: none;
  }

  &.is-active {
    .show-text {
      display: none;
    }

    .hide-text {
      display: inline;
    }
  }
}

.js-toggle-target {
  height: 0;
  transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;

  &:not(.is-opened) {
    overflow: hidden;
  }
}

.toggle--filters {
  color: $primary-color;
  display: inline-flex;
  align-items: center;
  transition: margin 0.3s ease-in-out;

  @include breakpoint(tablet) {
    display: none;
  }

  [data-icon] {
    @include icon-sizer(chevron, 14px, auto);
    margin-right: 7px;
    transform: translateY(-2px);
    transition: transform 0.2s ease-in-out;
  }

  &.is-active {
    margin-bottom: 20px;
  }

  .hide-text [data-icon] {
    transform: translateY(-3px) rotate(180deg);
  }
}

.toggle-target--filters {
  opacity: 0;

  &.is-active {
    opacity: 1;
  }

  @include breakpoint(tablet) {
    display: block;
    height: auto !important;
    opacity: 1;
    overflow: visible !important;
  }
}
