.element-testimonialslideralt {

    .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;
    }

    padding-top: 2rem;
    padding-bottom: 0;

    @include breakpoint(large) {
      padding-top: 4rem;
      padding-bottom: 0;
    }

    .grid-container {

        margin-left: inherit;

        @include breakpoint(large) {

            margin-left: 3rem;
        }

        @include breakpoint(xlarge) {

            margin-left: 2rem;
        }
        @include breakpoint(xxlarge) {
            margin-left: auto;
        }
    }

    .headline {
        text-align: center;

        padding-bottom: 4rem;

        @include breakpoint(large) {

            padding-bottom: 0;

        }

    }

    .testimonials-alt-container {
        background-color: $blue;
        position: relative;
        padding: 1rem 2rem;

    }

    .testimonial-wrap {
        position: relative;

        text-align: center;

        @include breakpoint(large) {
            text-align: left;
       }

    }

    .testimonial-headshot {

        display: inline;

        img {

            width: 150px;
            height: 150px;
            margin-top: -5rem;

            @include breakpoint(large) {
                margin-left: -5rem;

                position: absolute;
                top: 0;
                left: 0;

                width: 200px;
                height: 200px;

            }

            @include breakpoint(xlarge) {
                width: 250px;
                height: 250px;

            }

            border-radius: 50%;
        }
    }

    .testimonial-quote {
        color: #fff;
        margin-top: 1rem;

        @include breakpoint(large) {

            margin-left: 150px;
            padding-top: 2rem;
        }

        @include breakpoint(xlarge) {
            margin-left: 225px;

        }

        .testimonial-item__quote {

            font-style: italic;
            position: relative;

            svg {
                position: absolute;
                top: -40px;
                left: 0;
                z-index: -1;
                opacity: .5;
            }


        }

        .testimonial-item__person {
            font-weight: bold;
        }

    }

    .thumbs-wrapper {

        margin-top: 1rem;
        height: 65px;
        position: relative;

        margin-bottom: 1rem;

        @include breakpoint(large) {
            margin-top: 3rem;
            margin-left: 200px;
        }

        @include breakpoint(xlarge) {
            margin-left: 268px;
        }
    }

    .testimonials-alt-thumb-slider {

        display: block;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        width: 225px;

        @include breakpoint(large) {
            width: auto;
            max-width: 450px;
            display: inline-block;
            margin: 0;
        }

        @include breakpoint(xlarge) {
            max-width: 750px;
        }

        .swiper {
            list-style: none;
            margin-left: auto;
            margin-right: auto;
            overflow: hidden;
            padding: 0;
            position: relative;
            z-index: 1;
        }

        .swiper-wrapper {
            box-sizing: initial;
            display: flex;
            height: 100%;
            position: relative;
            transition-property: transform;
            width: 100%;
            z-index: 1;
        }

        .swiper-slide {

            max-width: 65px;
            cursor: pointer;
            padding-right: 10px;

            img {
                filter: grayscale(100%);
                border-radius: 50%;
            }
            &.swiper-slide-thumb-active {
                img {
                    filter: grayscale(0%)
                }
            }
            &:hover {
                img {
                    filter: grayscale(0%)
                }
            }

        }

        button {
            svg {
                display: block;
                width: 24px;
                height: 24px;
                path {
                    display: block;
                    fill: #fff;
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .slider-control-prev {
            left: -48px;
            position: absolute;
            top: 24px;
        }
        .slider-control-next {
            position: absolute;
            right: -36px;
            top: 24px;
        }

    }

}
