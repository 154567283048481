.event-sponsorship-form-wrapper {
  background: $primary-color;
  padding-bottom: 40px;
  color: $white;
  position: relative;
  display: none;
  margin-bottom: 45px;

  @include breakpoint(tablet) {
    padding: 12vw 0 40px;
    margin-top: 30px;
  }

  &:before {
    @include breakpoint(tablet) {
      content: '';
      background: $white;
      width: 105%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 50%;
      height: 15vw;
      top: -7vw;
    }
  }

  &__top {
    text-align: center;
    padding: 30px 0 45px;
  }

  &__title {
    @include rfs(55);
    color: $white;
  }

  fieldset {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  .form-success-message,
  .form-error-message {
    text-align: center;
  }

  .form-actions {
    border-top: 1px solid rgba($white, 0.15);
    padding-top: 30px;

    @include breakpoint(large) {
      padding-top: 45px;
    }

    button {
      background: transparent;
      border-color: $white;
      color: $white;

      &:hover,
      &:focus {
        background: $accent-color;
        border-color: $accent-color;
        color: $white;
      }

      margin: auto;
    }
  }
}

.event-levels-wrapper {
  background: #013752;
  color: $white;
  border-radius: 24px;
  padding: 30px 20px;

  @include breakpoint(tablet) {
    padding: 30px;
  }

  @include breakpoint(xxlarge) {
    padding: 30px 40px 40px;
  }

  &__inner {
    @include breakpoint(tablet) {
      display: flex;
      align-items: center;
    }
  }
}

.event-levels {
  margin-bottom: 20px;

  @include breakpoint(tablet) {
    margin: 0;
    flex: 1 0 50%;
    padding-right: 30px;
    border-right: 1px solid $white;
  }

  @include breakpoint(xlarge) {
    padding: 10px 50px 20px 30px;
  }

  &__title {
    @include rfs(24);
    color: $white;
  }

  ul {
    @include list-reset;
  }

  li {
    label {
      position: relative;
      display: inline-flex;
      align-items: flex-start;
      margin: 5px 0;
      line-height: 1.5;
      cursor: pointer;

      &:hover:before {
        background: lighten($primary-color, 40%);
      }

      &:before {
        background: white;
        content: '';
        width: 18px;
        height: 18px;
        border: 1px solid $white;
        border-radius: 50%;
        margin-right: 0.8em;
        transition: all 0.2s ease;
        flex-shrink: 0;
        position: relative;
        top: 3px;

        @include breakpoint(xlarge) {
          top: 5px;
        }
      }
    }

    input[type=radio] {
      position: absolute;
      visibility: hidden;

      &:checked ~ label:before {
        background: $primary-color;
      }
    }
  }
}

.event-honor-memory {
  @include breakpoint(tablet) {
    margin: 0;
    flex: 1 0 50%;
    padding-left: 30px;
  }

  @include breakpoint(xlarge) {
    padding: 10px 30px 20px 50px;
  }

  &__title {
    @include rfs(24);
    color: $white;
  }

  .form--styled:not(.form--fly) & .field__wrapper {
    margin-bottom: 1rem;
  }
}

.event-sponsorship-payment {
  padding-top: 20px;

  &__title {
    @include rfs(24);
    color: $white;
    margin-bottom: 0.3em;
  }

  &__notice {
    font-style: italic;
    margin-bottom: 20px;
  }

  &__card {
    background: $white;
    padding: 12px;
  }
}

.event-sponsorship-pay-options {
  @include breakpoint(tablet) {
    display: flex;
    justify-content: space-between;
  }

  & > div {
    margin: 20px 0;

    @include breakpoint(tablet) {
      width: calc(50% - 20px);
    }
  }

  &__check {
    @include breakpoint(tablet) {
      padding-left: 40px;
    }
  }

  &__radio {
    @include breakpoint(tablet) {
      margin-bottom: 15px;
    }

    label {
      @include rfs(24);
      position: relative;
      display: inline-flex;
      align-items: flex-start;
      line-height: 1.5;
      cursor: pointer;
      color: #013752;
      font-weight: bold;

      &:hover:before {
        background: lighten(#013752, 40%);
      }

      &:before {
        background: white;
        content: '';
        width: 18px;
        height: 18px;
        border: 1px solid $white;
        border-radius: 50%;
        margin-right: 0.5em;
        transition: all 0.2s ease;
        flex-shrink: 0;
        position: relative;
        top: 0.35em;
      }
    }

    input[type=radio] {
      position: absolute;
      visibility: hidden;

      &:checked ~ label:before {
        background: #013752;
      }
    }
  }
}

.event-sponsorship-form-callout {
  &__title {
    @include rfs(25);
    font-weight: bold;
    color: $primary-color;
    text-align: center;
    margin-bottom: 20px;
  }

  .btn {
    margin: auto;
    max-width: none;
    width: auto;
  }
}
