.pagetype-programspage {
  .search-empty-results {
    padding: 30px 0;
  }
}

.programs-items {
  @include list-reset;
  padding: 30px 0;
  position: relative;
  z-index: 1;

  @include breakpoint(tablet) {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 60px;
    margin: -60px -10px 0;
  }

  @include breakpoint(xxlarge) {
    margin: -60px -20px 0;
  }
}

.programs-item {
  $self: &;

  margin-bottom: 20px;

  @include breakpoint(tablet) {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
  }

  @include breakpoint(large) {
    width: percentage(1 / 3);
  }

  @include breakpoint(xxlarge) {
    padding: 0 20px;
    margin-bottom: 40px;
  }

  &__wrapper {
    border: 6px solid $white;
    border-radius: 6px;
    box-shadow: 0 2px 10px 0 rgba(black, 0.4);
    height: 100%;
  }

  &__link {
    display: block;
    position: relative;
    font-weight: 300;
    color: $white;

    &:before {
      content: '';
      background: linear-gradient(to bottom, transparent, rgba(black, 0.2));
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
    }

    &:hover,
    &:focus {
      color: $white;

      #{$self}__title {
        opacity: 0;
      }

      #{$self}__overlay {
        pointer-events: all;
        opacity: 1;
      }

      #{$self}__arrow [data-icon] {
        opacity: 1;
        margin-left: 1em;
      }
    }
  }

  &__title {
    @include rfs(25);
    text-transform: uppercase;
    line-height: 1.1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 20px;
    text-align: center;
    transition: opacity 0.3s ease-in-out;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(black, 0.7);

    img {
      max-width: 300px;
      width: 100%;
      margin: auto;
    }
  }

  &__county {
    @include font-serif;
    display: flex;
    align-items: center;
    font-style: italic;
    font-weight: bold;
    position: absolute;
    bottom: 15px;
    left: 15px;
    z-index: 2;

    img {
      margin-right: 10px;
    }
  }

  &__overlay {
    background: rgba($primary-color, 0.9);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    padding: 20px;
    text-align: center;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    &-title {
      @include rfs(20);
      text-transform: uppercase;
      line-height: 1.1;
      padding-bottom: 0.75em;
      margin: 0 auto 0.75em;
      border-bottom: 1px solid $white;
      display: inline-block;
      font-weight: bold;

      img {
        max-width: 145px;
        margin: auto;
        width: 100%;
      }
    }
  }

  &__lead-in {
    overflow: hidden;
    height: calc(100% - 60px);
    position: relative;
  }

  &__arrow-wrapper {
    height: 60px;
  }

  &__arrow {
    background: $accent-color;
    width: 90px;
    height: 60px;
    border-top-left-radius: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    position: absolute;
    bottom: 0;
    right: 0;

    [data-icon] {
      transition: all 0.3s ease-in-out;
      transition-delay: 0.2s;
      opacity: 0;
    }
  }
}
