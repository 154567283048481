@import '~foundation-sites/scss/util/util';

// 1. Global
// ---------

$global-font-size: 100%;
$global-width: rem-calc(1440);
$global-lineheight: 1.5;

$black: #2c2c2c;
$white: #fefefe;
$gold: #f2c744;
$blue: #007ab8;
$dark-blue: #013752;
$green: #3f8227;
$red: #b9112a;
$lighter-gray: #fafafa;
$light-gray: #e3e3e3;
$medium-gray: #d6d6d6;
$dark-gray: #757575;

$foundation-palette: (
  primary: $blue,
  accent: $gold,
  secondary: $green,
  success: #3adb76,
  warning: #ffae00,
  alert: $red,
);

$accent-color: get-color(accent);

@include add-foundation-colors;

$body-background: $white;
$body-font-color: $black;
$body-font-family: 'motiva-sans', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
$body-antialiased: true;
$global-margin: 1rem;
$global-padding: 1rem;
$global-position: 1rem;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-radius: 0;
$global-menu-padding: 0.7rem 1rem;
$global-menu-nested-margin: 1rem;
$global-text-direction: ltr;
$global-flexbox: true;
$global-prototype-breakpoints: false;
$global-button-cursor: pointer;
$global-color-pick-contrast-tolerance: 0;
$print-transparent-backgrounds: true;

// App
// --------------

$site-menu-width: 320px;

$panel-padding: (
  small: 30px,
  tablet: 45px,
  large: 60px,
  xlarge: 75px,
  xxlarge: 90px,
);

// 2. Breakpoints
// --------------

$breakpoints: (
  small: 0,
  medium: 640px,
  tablet: 768px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
);
$print-breakpoint: large;
$breakpoint-classes: (small medium tablet large xlarge);

// 3. The Grid
// -----------

$grid-row-width: $global-width;
$grid-column-count: 12;
$grid-column-gutter: (
  small: 20px,
  medium: 30px,
);
$grid-column-align-edge: true;
$grid-column-alias: 'columns';
$block-grid-max: 8;

// 56. Xy Grid
// -----------

$xy-grid: true;
$grid-container: $global-width;
$grid-columns: 12;
$grid-margin-gutters: (
  small: 20px,
  medium: 30px,
);
$grid-padding-gutters: $grid-margin-gutters;
$grid-container-padding: (
  small: 20px,
  medium: 30px,
  tablet: 40px,
  large: 50px,
  xlarge: 60px,
);
$grid-container-max: $global-width;
$xy-block-grid-max: 8;

*:focus {
  outline: none;
  box-shadow: 0 0 1px 1px #0572c933;
}
