.element-featuredevents {
  .panel-title {
    margin: auto auto 0.75em;
    max-width: 820px;
    line-height: 1.1;
  }
}

.featured-events-wrapper {
  @include panel-padding;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;

  &:before {
    content: '';
    background: rgba(black, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }

  & > div {
    position: relative;
  }
}

.featured-events-items {
  margin-bottom: 40px;
  text-align: center;

  @include breakpoint(tablet) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.featured-events-item {
  padding: 20px 0;
  position: relative;

  @include breakpoint(tablet) {
    width: percentage(1 / 3);
    padding: 20px;
  }

  &:before {
    @include breakpoint(large) {
      content: '';
      border-left: 1px solid $white;
      height: calc(100% - 80px);
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &:nth-child(3n + 1):before {
    display: none;
  }

  &__link {
    @include rfs(25);
    color: $white;
    text-align: center;
    display: inline-block;
    margin: auto;

    &:hover,
    &:focus {
      color: $accent-color;
    }
  }

  &__thumb {
    margin-bottom: 1em;
  }

  img {
    border-radius: 50%;
    max-width: 170px;
    margin: auto;
  }
}

.featured-events-actions {
  .btn {
    margin: auto;
  }
}
