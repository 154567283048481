.has-page-container {
  .site-header--fixed + .site-wrapper .page-header {
    @include breakpoint(medium down) {
      padding-bottom: 0;
    }
  }

  .page-container {
    padding: 30px 0;
    position: relative;
    z-index: 1;

    @include breakpoint(tablet) {
      padding: 40px;
      background: $white;
      border-top: 1px solid transparent;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      box-shadow: 0 2px 10px 0 rgba(black, 0.25);
      margin-top: -40px;
    }

    @include breakpoint(xlarge) {
      padding: 50px 60px;
      margin-top: -60px;
    }

    &:before {
      @include breakpoint(tablet) {
        content: '';
        background-image: linear-gradient(to bottom, rgba($white, 0), $white);
        background-repeat: no-repeat;
        position: absolute;
        height: calc(100% - 40px);
        width: calc(100% + 20px);
        left: -10px;
        top: 40px;
        z-index: 0;
      }

      @include breakpoint(xlarge) {
        height: calc(100% - 60px);
        top: 60px;
      }
    }

    &:after {
      @include breakpoint(tablet) {
        content: '';
        position: absolute;
        background: $white;
        height: 40px;
        width: calc(100% + 20px);
        left: -10px;
        bottom: -40px;
        z-index: 1;
      }

      @include breakpoint(xlarge) {
        height: 60px;
        bottom: -60px;
      }
    }

    &__inner {
      position: relative;
      z-index: 2;
    }
  }
}
