$rfs-breakpoint: 1440;

@mixin font-sans-serif {
    font-family: 'motiva-sans', sans-serif;
}

@mixin font-serif {
    font-family: 'eb-garamond', serif;
}

// File type icons
$fileIcons: (
    pdf: ft-pdf,
    doc: ft-doc,
    docx: ft-doc,
    dotx: ft-doc,
    ppt: ft-ppt,
    pptx: ft-ppt,
    xls: ft-xls,
    xlsx: ft-xls,
    book: ft-book,
);

@each $ext, $icon in $fileIcons {
    a.ft-#{$ext},
    a[href$='.#{$ext}']:not(.btn):not(.ignore-ft) {
        display: inline-flex;
        line-height: 1.4;
        align-items: flex-start;

        &:before {
            content: '';
            display: block;
            background-image: url(~images/#{$icon}.svg);
            background-repeat: no-repeat;
            background-size: contain;
            height: 24px;
            width: 24px;
            margin-right: 10px;
            flex-shrink: 0;
            position: relative;
            top: -3px;

            @include breakpoint(xxlarge) {
                top: -1px;
            }
        }
    }
}

p {
    margin-top: 0;
}

a {
    color: $primary-color;
    font-weight: bold;
    text-decoration: none;

    &:hover,
    &:focus {
        color: $primary-color;
    }
}

address {
    font-style: normal;
}

strong {
    font-weight: bold;
}

button {
    outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $primary-color;
    margin: 0 0 1.2rem;
    line-height: 1.3;
}

[data-icon] {
    overflow: hidden;
    pointer-events: none;
}

// Reads the dimensions from the $sizes map provided by the webpack plugin
// Sets the width and height for the icon based on its original dimensions
// This allows the icons to display at their original dimensions, but also be overridable
// https://github.com/cascornelissen/svg-spritemap-webpack-plugin
@each $icon, $dimensions in $sizes {
    [data-icon='#{$icon}'] {
        width: map-get($dimensions, width);
        height: map-get($dimensions, height);
    }
}

.headline {
  @include font-sans-serif;
  font-size: 24px;

  @include breakpoint(tablet) {
    font-size: 36px;
  }

  @include breakpoint(xlarge) {
    font-size: 50px;
  }
}

.headline-lg {
  @include font-sans-serif;
  font-size: 24px;

  @include breakpoint(tablet) {
    font-size: 36px;
  }

  @include breakpoint(xlarge) {
    font-size: 60px;
  }
}

.text-left {
  text-align: left;
}
