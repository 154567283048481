.element-faqs {
  @include panel-padding;

  .panel-title {
    margin-bottom: 30px;

    @include breakpoint(tablet) {
      margin-bottom: 45px;
    }

    @include breakpoint(large) {
      margin-bottom: 60px;
    }

    @include breakpoint(xxlarge) {
      margin-bottom: 80px;
    }
  }

  .cell:first-child .faqs-list .faqs-item:first-child {
    @include breakpoint(medium down) {
      margin-top: 0;
    }
  }
}

.faqs-list {
  @include list-reset;
  padding-bottom: 45px;

  .faqs-item:first-child {
    @include breakpoint(tablet) {
      margin-top: 0;
    }
  }
}

.faqs-item {
  box-shadow: 0 0 6px 0 rgba(black, 0.35);
  border-radius: 40px;
  max-width: 650px;
  padding: 20px;
  margin-top: 30px;

  @include breakpoint(tablet) {
    margin-top: 40px;
  }

  @include breakpoint(large) {
    border-radius: 50px;
    margin-top: 45px;
  }

  @include breakpoint(xxlarge) {
    border-radius: 60px;
    margin-top: 60px;
  }

  &__symbol {
    background: #0383c4;
    color: $white;
    border-radius: 50%;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    font-size: 20px;
    line-height: 1;

    @include breakpoint(large) {
      width: 60px;
      height: 60px;
      font-size: 30px;
    }

    @include breakpoint(xxlarge) {
      width: 80px;
      height: 80px;
      font-size: 44px;
      margin-right: 30px;
    }
  }

  &__question {
    color: #013752;
    display: flex;
    line-height: 1.2;
    font-size: 18px;
    align-items: center;
    padding-right: 15px;

    @include breakpoint(large) {
      @include rfs(22);
      padding-right: 20px;
    }
  }

  &__answer {
    padding: 20px 5px;

    @include breakpoint(large) {
      padding: 20px 40px 20px 80px;
    }

    @include breakpoint(xxlarge) {
      padding: 0 60px 20px 110px;
    }

    p:first-child {
      @include breakpoint(large) {
        border-top: 1px solid $light-gray;
        padding-top: 20px;
      }
    }

    p:last-child {
      margin: 0;
    }
  }
}
