.stories-header {
  background-color: $white;

  @include breakpoint(large) {
    min-height: 480px;
  }

  @include breakpoint(xlarge) {
    min-height: 560px;
  }

  &:before {
    z-index: 1;
  }
}

.stories-header-images {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;

  &__slide {
    width: 100%;
    height: 100%;
    background-size: cover;
  }
}

.stories-header-content {
  text-align: center;

  &__slide {
    width: 100%;
    padding: 20px 0;
  }

  &__link {
    display: inline-block;
    margin: auto;

    &:hover,
    &:focus {
      .stories-header-content__title {
        color: $accent-color;
      }
    }
  }

  &__program {
    @include rfs(25);
    text-transform: uppercase;
    line-height: 1.1;
    color: $white;
    margin-bottom: 20px;

    img {
      max-width: 125px;
      margin: auto;
    }
  }

  &__title {
    @include rfs(65);
    @include font-serif;
    color: $white;
    line-height: 1;
    transition: color 0.2s ease-in-out;

    &:before {
      content: '';
      display: block;
      width: 80%;
      border-top: 1px solid $white;
      margin: auto auto 10px;
    }
  }

  .flickity-viewport {
    z-index: 2;
  }

  .flickity-slider {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flickity-page-dots {
    position: relative;
    bottom: auto;
    z-index: 5;
    margin: 20px auto auto;

    .dot {
      border: 1px solid $light-gray;
      background: $light-gray;
      opacity: 1;
      height: 14px;
      width: 14px;
      margin: 0 5px;

      &.is-selected {
        background: $accent-color;
        border-color: $accent-color;
      }
    }
  }

  &.is-single {
    .flickity-page-dots {
      display: none;
    }
  }
}

.stories-search {
  .page-search__inner {
    max-width: 1050px;
    margin: auto;

    @include breakpoint(tablet) {
      flex-wrap: wrap;
    }
  }

  .page-search__filters {
    @include breakpoint(tablet only) {
      width: 100%;

      .page-search__field:last-child {
        margin-right: 0;
      }
    }
  }

  .stories-search__program {
    @include breakpoint(large) {
      width: 50%;
    }
  }

  .stories-search__keyword {
    @include breakpoint(large) {
      width: 50%;
    }

    input {
      background: $white;
    }
  }

  .btn--subscribe {
    @include breakpoint(tablet only) {
      margin-top: 20px;
      margin-left: auto;
    }

    @include breakpoint(large only) {
      max-width: 160px;
    }
  }

  .page-search-results-heading {
    width: 100%;
  }
}

.pagetype-storypage {
  .search-empty-results {
    padding: 30px 0;
  }
}
